import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    Input,
    Output,
} from "@angular/core";

import { BannerOptions, ButtonStyle } from "../custom-layout";

@Component({
    selector: "cloudextend-app-banner",
    templateUrl: "./app-banner.component.html",
    styleUrls: ["./app-banner.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppBannerComponent {
    @Input() productTag = "";

    @Input() options: BannerOptions;

    @Output() menuButtonClicked = new EventEmitter<void>();
    @Output() backButtonClicked = new EventEmitter<void>();

    buttonStyle = ButtonStyle;

    public onBackButtonClicked() {
        this.backButtonClicked.emit();
    }

    public onMenuButtonClicked() {
        this.menuButtonClicked.emit();
    }
}
