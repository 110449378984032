import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { MatSnackBarModule } from "@angular/material/snack-bar";
import { EffectsModule } from "@ngrx/effects";

import { NotificationsEffects } from "./notifications.effects"

@NgModule({
    declarations: [],
    imports: [
        CommonModule,
        MatSnackBarModule,
        EffectsModule.forFeature([NotificationsEffects]),
    ],
})
export class NotificationsModule {}
