import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { Route, RouterModule } from "@angular/router";
import { EffectsModule } from "@ngrx/effects";

import { CustomLayoutData } from "@cloudextend/common/ui";

import { NoBundlePageComponent, NoContextPageComponent } from "./common";
import {
    MessageContextPageComponent,
    WelcomePageComponent,
} from "./contextual";
import { getSkipWelcome } from "./preferences";
import { ContextualRouteGuard } from "./routing/contextual-route-guard.service";
import { ContextRoutingEffects } from "./routing/effects/contextual-routing.effects";
import { SkippablePageConfig } from "./routing/skippable-page-config";
import { SkippablePageRouteGuardService } from "./routing/skippable-page.guard";
import { UnintededRoutingGuard } from "./routing/unintended-routing.guard";

const routes = [
    {
        path: "home",
        canActivate: [ContextualRouteGuard],
        component: NoContextPageComponent,
        // This component will never be loaded.
        // It is only there to satisfy Angular's validation rules. The ContextualRouteGuard will
        // ensure that the right page gets loaded.
    },
    {
        path: "message/read/:itemId",
        component: WelcomePageComponent,
        canActivate: [SkippablePageRouteGuardService],
        data: {
            skippable: {
                canSkip: getSkipWelcome,
                skipTo: "actions",
            } as SkippablePageConfig,
        },
    },
    {
        path: "message/read/:itemId/actions",
        canActivate: [UnintededRoutingGuard],
        component: MessageContextPageComponent,
    },
    {
        path: "no-context",
        component: NoContextPageComponent,
        canActivate: [UnintededRoutingGuard],
        data: {
            layout: { bannerOptions: { hideSearchBox: true } },
        } as CustomLayoutData,
    },
    { path: "", redirectTo: "/no-context", pathMatch: "full" },
    {
        path: "no-bundle",
        component: NoBundlePageComponent,
        canActivate: [UnintededRoutingGuard],
        data: {
            layout: { bannerOptions: { hideSearchBox: true } },
        } as CustomLayoutData,
    },
] as Route[];

@NgModule({
    declarations: [],
    imports: [
        CommonModule,
        RouterModule.forChild(routes),
        EffectsModule.forFeature([ContextRoutingEffects]),
    ],
})
export class PrpRoutingModule {}
