import { Injectable } from "@angular/core";
import { Store } from "@ngrx/store";

import { views } from "@cloudextend/common/state";

import { Workflow } from "./workflow";
import { WorkflowStep } from "./workflow-step";

@Injectable()
export class DefaultWorkflow extends Workflow {
    constructor(store: Store) {
        super("#workflows/Default Workflow", store);
    }

    public get onCompletion() {
        return () => views.home(this.name);
    }

    public get steps(): WorkflowStep[] {
        return [];
    }
}
