import { Component, ChangeDetectionStrategy } from "@angular/core";
import { Store } from "@ngrx/store";
import { AnimationOptions } from "ngx-lottie";

import { getUserDisplayName } from "@cloudextend/cefi/state";

@Component({
    selector: "prp-no-context-page",
    templateUrl: "./no-context-page.component.html",
    styleUrls: ["./no-context-page.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NoContextPageComponent {
    constructor(private readonly store: Store) {}

    displayName$ = this.store.select(getUserDisplayName);

    options: AnimationOptions = {
        path: "assets/animations/no-content.json",
    };
}
