<mat-toolbar
    color="primary"
    class="main-toolbar"
    *ceLet="options || {} as _options"
>
    <mat-toolbar-row [ngSwitch]="_options.buttonStyle">
        <span *ngSwitchCase="buttonStyle.hidden"></span>
        <button
            *ngSwitchCase="buttonStyle.back"
            class="main-toolbar-menu-button"
            mat-button
        >
            <mat-icon
                class="main-toolbar-icon"
                matBadgeColor="accent"
                matBadgePosition="above after"
                (click)="onBackButtonClicked()"
                >arrow_back</mat-icon
            >
        </button>
        <button
            *ngSwitchDefault
            class="main-toolbar-menu-button"
            mat-button
            (click)="onMenuButtonClicked()"
        >
            <mat-icon
                class="main-toolbar-icon"
                matBadgeColor="accent"
                matBadgePosition="above after"
                >menu</mat-icon
            >
        </button>
        <span *ngIf="!_options.title" class="main-toolbar-title inline"
            >CloudExtend {{ productTag }}</span
        >
        <span *ngIf="_options.title" class="main-toolbar-title inline">{{
            _options.title
        }}</span>
    </mat-toolbar-row>

    <mat-toolbar-row
        class="main-toolbar-search"
        *ngIf="!_options.hideSearchBox"
    >
        <ng-content></ng-content>
    </mat-toolbar-row>
</mat-toolbar>
