<div
    [ngSwitch]="autopilotStatus"
    *ngIf="autopilotStatus !== AutopilotStatus.optOut"
>
    <span *ngSwitchCase="AutopilotStatus.inactiveOnThread">
        Turn on Autopilot <mat-icon>question</mat-icon>&nbsp;<mat-checkbox
        ></mat-checkbox>
    </span>

    <span *ngSwitchCase="AutopilotStatus.activeOnThread">
        This thread is on Autopilot.
    </span>

    <span *ngSwitchDefault class="tired-of-saving">
        Tired of saving mails one by one? <a href="#">Try Autopilot</a>
    </span>
</div>
