import { Injectable } from "@angular/core";
import { Actions, createEffect } from "@ngrx/effects";
import { Store } from "@ngrx/store";
import { catchError, map, switchMap } from "rxjs/operators";

import { events } from "@cloudextend/cefi/state";
import { busy, idle, LocalStorageService } from "@cloudextend/common/core";
import { onEvent } from "@cloudextend/common/events";
import { PingService } from "@cloudextend/ecosystems/common";

import { MailboxService } from "./mailbox";
import { BundleState } from "./model/bundle-state";
import { noBundle } from "./views";
const BUNDLE_STATE_STORAGE_KEY = "netsuite.bundle.state";
const DAY_IN_MILIS = 86400000;
@Injectable()
export class PrpInitializerEffects {
    constructor(
        private readonly actions$: Actions,
        private readonly mailboxService: MailboxService,
        private readonly pingSvc: PingService,
        private readonly storageSvc: LocalStorageService,
        private readonly store: Store
    ) {}

    pingBundleOnLogIn$ = createEffect(
        () =>
            this.actions$.pipe(
                onEvent(events.cefiInitialized),
                switchMap(() => {
                    this.store.dispatch(
                        busy(this.name, {
                            message:
                                "Initializing the app & checking NetSuite account for the SuiteApp",
                        })
                    );
                    const bundleState = this.storageSvc.get(
                        BUNDLE_STATE_STORAGE_KEY
                    );
                    if (
                        bundleState &&
                        JSON.parse(bundleState).state?.ttl >
                            new Date().getTime()
                    ) {
                        return;
                    }
                    return this.pingSvc.ping().pipe(
                        map(state => {
                            if (state) {
                                //Note: ping call might not be required for webservices.
                                this.savePingState(state);
                                this.mailboxService.initialize();
                            } else {
                                this.store.dispatch(noBundle(this.name));
                            }
                            this.store.dispatch(idle(this.name));
                        }),
                        catchError(async () => {
                            this.store.dispatch(idle(this.name));
                        })
                    );
                })
            ),
        { dispatch: false }
    );

    removePingStateFromCache$ = createEffect(
        () =>
            this.actions$.pipe(
                onEvent(events.loggedOut),
                map(() => {
                    this.storageSvc.removeItem(BUNDLE_STATE_STORAGE_KEY);
                })
            ),
        { dispatch: false }
    );

    destroyMailBoxEventHander$ = createEffect(
        () =>
            this.actions$.pipe(
                onEvent(events.loggedOut),
                map(() => {
                    this.mailboxService.destroyEventHandler();
                })
            ),
        { dispatch: false }
    );

    private savePingState(state: boolean) {
        const bundleStateJson: BundleState = {
            isAvailable: state,
            ttl: new Date().getTime() + DAY_IN_MILIS,
        };
        this.storageSvc.save(
            BUNDLE_STATE_STORAGE_KEY,
            JSON.stringify(bundleStateJson)
        );
    }

    protected get name() {
        return "PrpInitializer/Effects";
    }
}
