import { HttpClient } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { Store } from "@ngrx/store";
import { Observable, of, throwError } from "rxjs";
import { flatMap, switchMap } from "rxjs/operators";

import { CefiClient } from "@cloudextend/cefi/core";
import { getActiveConnection } from "@cloudextend/cefi/state";
import { ENV, Environment, takeOnce } from "@cloudextend/common/core";

@Injectable({
    providedIn: "root",
})
export class NetsuiteClientService extends CefiClient {
    constructor(
        http: HttpClient,
        private readonly store: Store,
        @Inject(ENV) environment: Environment
    ) {
        super(http, environment);
    }

    public static readonly NS_ACCOUNT_HEADER = "x-ce-ns-account";
    public static readonly NS_KEY_HEADER = "x-ce-ns-key";

    public get<ResponseType>(path: string): Observable<ResponseType> {
        return this.getHeaders().pipe(
            switchMap(headers => {
                const httpHeaders = this.createHeaders(headers);
                const encodedPath = encodeURI(path);
                return this.http.get<ResponseType>(
                    `${this.environment.apiBaseUrl}${encodedPath}`,
                    { headers: httpHeaders, withCredentials: true }
                );
            })
        );
    }

    public post<ResponseType>(
        path: string,
        request: string
    ): Observable<ResponseType> {
        return this.getHeaders().pipe(
            switchMap(headers => {
                let httpHeaders = this.createHeaders(headers);
                if (!headers["x-ce-ns-account"] || !headers["x-ce-ns-key"]) {
                    throw new Error(
                        "Unable to continue the POST request wihtout mandatory header properties"
                    );
                }

                const token = this.getXsrfToken();
                if (token) {
                    httpHeaders = httpHeaders.append("x-ce-xsrf-token", token);
                }
                return this.http.post<ResponseType>(
                    `${this.environment.ecsBaseUrl}${path}`, // TODO PRP-397
                    request,
                    { headers: httpHeaders, withCredentials: true }
                );
            })
        );
    }

    private getHeaders() {
        return this.store.select(getActiveConnection).pipe(
            takeOnce(),
            flatMap(connection => {
                if (
                    !connection ||
                    !connection.account ||
                    !connection.encryptedConnection
                ) {
                    return throwError(
                        "Unable to make the request without mandatory NetSuite connection properties"
                    );
                }

                return of({
                    [NetsuiteClientService.NS_ACCOUNT_HEADER]:
                        connection.account,
                    [NetsuiteClientService.NS_KEY_HEADER]:
                        connection.encryptedConnection,
                });
            })
        );
    }
}
