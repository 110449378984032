<div class="main-menu-actions-list">
    <div class="main-menu-header">
        <div class="user-avatar" (click)="menuActionCompleted.emit()">
            <span class="initials">{{ getUserInitials(userDisplayName) }}</span>
        </div>
        <div class="username">{{ userDisplayName || "Welcome!" }}</div>
        <div class="account-number">
            {{ accountNumber || "Please connect to a NetSuite account" }}
        </div>
    </div>
    <mat-divider></mat-divider>
    <ng-content></ng-content>
</div>
