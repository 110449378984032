import { ChangeDetectionStrategy, Component } from "@angular/core";
import { Store } from "@ngrx/store";

import { getUserDisplayName } from "@cloudextend/cefi/state";
import { takeOnce } from "@cloudextend/common/core";
import { MessageContext, views } from "@cloudextend/prp/core";

import * as Preferences from "../../preferences";

const WELCOME_PAGE = "prp:ui:contextual:WelcomePage";

@Component({
    selector: "prp-welcome-page",
    templateUrl: "./welcome-page.component.html",
    styleUrls: ["./welcome-page.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class WelcomePageComponent {
    constructor(private readonly store: Store) {}

    public readonly userDisplayName$ = this.store.select(getUserDisplayName);

    public readonly skipWelcome$ = this.store.select(
        Preferences.getSkipWelcome
    );

    private readonly currentMailItem$ = this.store.select(
        MessageContext.getCurrentMailItem
    );

    public toggleSkipWelcome(): void {
        this.store.dispatch(Preferences.skipWelcomeToggled("Pages/Welcome"));
    }

    public showSuggestedRecords(): void {
        this.currentMailItem$.pipe(takeOnce()).subscribe({
            next: item =>
                this.store.dispatch(
                    views.messageReadActions(WELCOME_PAGE, { itemId: item.id })
                ),
        });
    }
}
