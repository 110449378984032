import { Injectable } from "@angular/core";
import { Actions, createEffect } from "@ngrx/effects";
import { map } from "rxjs/operators";

import { SubscriptionService } from "@cloudextend/cefi/core";
import { onEvent } from "@cloudextend/common/events";

import { loggedOut } from "../logout";

import { subscriptionUpdated } from "./events";

@Injectable()
export class UserSubscriptionEffects {
    constructor(
        private readonly actions$: Actions,
        private readonly subService: SubscriptionService
    ) {}

    updateSubscriptionInCache$ = createEffect(
        () =>
            this.actions$.pipe(
                onEvent(subscriptionUpdated),
                map(event =>
                    this.subService.cacheSubscription(event.subscription)
                )
            ),
        { dispatch: false }
    );

    removeSubscriptionFromCache$ = createEffect(
        () =>
            this.actions$.pipe(
                onEvent(loggedOut),
                map(() =>
                    this.subService.removeSubscriptionFromCache()
                )
            ),
        { dispatch: false }
    );
}
