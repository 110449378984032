import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { Route, RouterModule } from "@angular/router";

import { CefiRoutesModule } from "@cloudextend/cefi/routes";
import {
    RouterStateModule,
    ViewStatesModule,
} from "@cloudextend/common/routes";
import { PrpRoutingModule } from "@cloudextend/prp/ui";

const routes: Route[] = [
    {
        path: "cefi/login/google",
        loadChildren: () =>
            import("@cloudextend/cefi/auth/google").then(
                m => m.CefiAuthGoogleModule
            ),
    },
    {
        path: "cefi/connect/netsuite",
        loadChildren: () =>
            import("@cloudextend/cefi/connectors/netsuite").then(
                m => m.NetSuiteConnectionModule
            ),
    },
];
@NgModule({
    declarations: [],
    imports: [
        RouterModule.forRoot(routes, {
            useHash: true,
            initialNavigation: "enabled",
            relativeLinkResolution: "legacy",
            // enableTracing: true,
        }),
        CommonModule,
        RouterStateModule,
        ViewStatesModule,
        CefiRoutesModule,
        PrpRoutingModule,
    ],
})
export class AppRoutingModule {}
