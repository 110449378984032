import { SessionLifetime } from "@cloudextend/cefi/core";
import { createEventReducer, when } from "@cloudextend/common/events";

import { sessionLifetimeUpdated } from "./events";

export type State = SessionLifetime;

export const STATE_KEY = "lifetime";

const initialState: State = {
    idTokenExpiresInEpochSeconds: 0,
    refreshTokenExpiresInEpochSeconds: 0,
};

export const reducer = createEventReducer(
    initialState,
    when(sessionLifetimeUpdated, (state, event) => ({
        ...state,
        ...event.lifetime,
    }))
);
