<div class="busy-overlay" *ngIf="isBusy$ | async">
    <mat-progress-bar
        class="busy-progressbar"
        mode="indeterminate"
    ></mat-progress-bar>
    <div class="busy-overlay-conent">
        <div class="busy-overlay-spinner">
            <button
                *ngIf="isCancellable$ | async"
                mat-flat-button
                (click)="onCancel()"
            >
                Cancel
            </button>
        </div>
        <p>{{ message$ | async }}</p>
        <p class="busy-sub-text">{{ subText$ | async }}</p>
    </div>
</div>
