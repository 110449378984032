import {
    Component,
    EventEmitter,
    HostListener,
    Input,
    Output,
} from "@angular/core";

@Component({
    selector: "cloudextend-callout-button",
    templateUrl: "./callout-button.component.html",
    styleUrls: ["./callout-button.component.scss"],
})
export class CalloutButtonComponent {
    @Input() title: string;
    @Input() description: string;

    @Output() clicked = new EventEmitter();

    @HostListener("click")
    public onDocumentClick() {
        this.clicked.emit();
    }
}
