import { CommonErrorCodes } from "../constants/common-error-codes";
import { Fault } from "../exceptions/fault";

import { ClientStorage } from "./client-storage";
import { StorageService } from "./storage.service";

export class SharedClientStorage implements ClientStorage {
    constructor(private _storageManager: StorageService) {}

    set = (key: string, value: string) => this.save(key, value);

    save = (key: string, value: string) =>
        this._storageManager.getUnderlyingStorage().setItem(key, value);

    get = (key: string): string | undefined =>
        this._storageManager.getUnderlyingStorage().getItem(key) || undefined;

    clear = () => {
        this.getSessionStorage().clear();
        this._storageManager.getUnderlyingStorage().clear();
    };

    removeItem = (key: string) => {
        this._storageManager.getUnderlyingStorage().removeItem(key);
        this.getSessionStorage().removeItem(key);
    };

    private getSessionStorage = (): Storage => {
        if (!sessionStorage) {
            throw {
                code: CommonErrorCodes.storageDisabled,
                title: "SessionStorage is unavailable",
            } as Fault;
        }
        return sessionStorage;
    };
}
