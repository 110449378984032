import {
    ActionReducerMap,
    createFeatureSelector,
    createSelector,
} from "@ngrx/store";

import { Session } from "@cloudextend/cefi/core";
import { RxEvent } from "@cloudextend/common/events";

import * as fromConnection from "./connection/reducer";
import * as fromIdentity from "./identity/reducer";
import * as fromSessionLifetime from "./session-lifetime/reducer";
import { selectUserState } from "./user-state";

export interface State {
    [fromConnection.STATE_KEY]: fromConnection.State;
    [fromSessionLifetime.STATE_KEY]: fromSessionLifetime.State;
    [fromIdentity.STATE_KEY]: fromIdentity.State;
}

export const reducers: ActionReducerMap<State, RxEvent> = {
    [fromConnection.STATE_KEY]: fromConnection.reducer,
    [fromSessionLifetime.STATE_KEY]: fromSessionLifetime.reducer,
    [fromIdentity.STATE_KEY]: fromIdentity.reducer,
};

export const getSession = createSelector(selectUserState, userState => {
    const state = userState as State;
    return {
        identity: state[fromIdentity.STATE_KEY],
        lifetime: state[fromSessionLifetime.STATE_KEY],
    } as Session;
});
