import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";

import { Logger, LogService } from "@cloudextend/common/core";
import {
    GlobalSearchService,
    SearchResult,
} from "@cloudextend/ecosystems/common";

import { MailItem } from "../model";

const SEARCH_SEPERATOR = " OR ";
@Injectable({
    providedIn: "root",
})
export class RecordsSuggestionsService {
    private readonly logger: Logger;

    constructor(
        logService: LogService,
        private globalSearchSvc: GlobalSearchService
    ) {
        this.logger = logService.createLogger("RecordsSuggestionsService");
    }

    fetchSuggestions = (mailItem: MailItem): Observable<SearchResult[]> => {
        const searchText = this.getGlobalsearchText(mailItem);
        return this.globalSearchSvc
            .search(searchText)
            .pipe(map(page => page.records));
    };

    getGlobalsearchText = (mail: MailItem): string => {
        const queryArray: string[] = mail.recipientsTo.map(contact => {
            return contact.name
                ? contact.email + SEARCH_SEPERATOR + contact.name
                : contact.email;
        });

        const sender = mail.sender.name
            ? mail.sender.email + SEARCH_SEPERATOR + mail.sender.name
            : mail.sender.email;
        queryArray.push(sender);

        return queryArray.join(SEARCH_SEPERATOR).trim();
    };
}
