import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    Input,
    Output,
    ViewChild,
} from "@angular/core";
import { MatSelectionList } from "@angular/material/list";

import { recordsComparer } from "@cloudextend/common/core";
import { Candidate, CandidateSource } from "@cloudextend/prp/core";

@Component({
    selector: "prp-available-records-list",
    templateUrl: "./available-records-list.component.html",
    styleUrls: ["./available-records-list.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AvailableRecordsListComponent {
    @Input() public records: Candidate[];

    @Input() public showAttachButton: boolean;

    @Input() public selection: Candidate[] = [];
    @Output() selectionChange = new EventEmitter<Candidate[]>();

    @Output() attachmentButtonClicked = new EventEmitter<Candidate>();

    @ViewChild("recordsList") recordsList: MatSelectionList;

    public recordsComparer = recordsComparer;

    public getListItemCssClass(record: Candidate): string {
        let cssClass = "candidate-source-suggestion";

        switch (record.source) {
            case CandidateSource.explicitSearch:
                cssClass = "candidate-source-search";
                break;
            case CandidateSource.newlyCreated:
                cssClass = "candidate-source-newrecord";
                break;
            default:
                break;
        }
        return cssClass;
    }

    public onSelectionChanged(selectedItems: Candidate[]): void {
        this.selectionChange.emit(selectedItems);
    }

    public onSelectAllChanged(select: boolean) {
        if (select) {
            this.recordsList.selectAll();
        } else {
            this.recordsList.deselectAll();
        }
    }

    public onAttachmentButtonClicked($event: Event, record: Candidate) {
        $event.stopPropagation();
        this.attachmentButtonClicked.emit(record);
    }
}
