import {
    AfterViewInit,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnDestroy,
    Output,
    ViewChild,
} from "@angular/core";
import { MatSelectionListChange } from "@angular/material/list";
import { Router } from "@angular/router";
import {
    BehaviorSubject,
    fromEvent,
    merge,
    Observable,
    of,
    Subscription,
} from "rxjs";
import { debounceTime, filter, map, switchMap } from "rxjs/operators";

import { RecordRef } from "@cloudextend/common/core";
import {
    GlobalSearchService,
    ResultsPage,
} from "@cloudextend/ecosystems/common";

import { DropdownComponent } from "./internals/dropdown.component";

const INTERIM_RESULT: ResultsPage = {
    records: [],
    pageContext: { pageIndex: -1 },
};

// TODO: Replace FilerOption with RecordType
export interface FilterOption {
    text: string;
    id?: string;
}

@Component({
    selector: "cloudextend-main-search-box",
    templateUrl: "./main-search-box.component.html",
    styleUrls: ["./main-search-box.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MainSearchBoxComponent implements AfterViewInit, OnDestroy {
    // Main Searchbox Component with custom drop down.
    constructor(
        private readonly searchService: GlobalSearchService,
        changeDetector: ChangeDetectorRef,
        router: Router
    ) {
        this.subscriptions.push(
            router.events.subscribe(() => {
                this.searchText = "";
                changeDetector.markForCheck();
            })
        );
    }

    @Input()
    public placeholder = "Search for records";

    @Input()
    public filter: FilterOption | undefined;

    @Input()
    public searchText = "";

    @Input()
    public supportedFilters = [
        // { text: "Global Search" },
        // { text: "Contact", id: "contact" },
        // { text: "Customer", id: "customer" },
        // { text: "Employee", id: "employee" },
    ] as FilterOption[];

    @Input()
    public suggestions = ["cu: <name>", "con: <name>", "emp: <name>"];

    @Output()
    public recordSelected = new EventEmitter<RecordRef>();

    public searchResults$ = new BehaviorSubject<ResultsPage>(INTERIM_RESULT);

    private readonly subscriptions: Subscription[] = [];

    @ViewChild("input")
    public input!: ElementRef;

    @ViewChild(DropdownComponent)
    public dropdown!: DropdownComponent;

    public ngAfterViewInit() {
        const searchText$ = fromEvent(this.input.nativeElement, "keyup").pipe(
            switchMap((event: Event) =>
                of((event.target["value"] as string) || "")
            )
        );

        const reset$ = searchText$.pipe(
            filter(value => !this.isTextSearchable(value)),
            map(() => INTERIM_RESULT)
        );
        const search$ = searchText$.pipe(
            filter(value => this.isTextSearchable(value)),
            debounceTime(1000),
            switchMap(searchText => this.getSearchResults(searchText))
        );

        this.subscriptions.push(
            merge(reset$, search$).subscribe({
                next: results => this.searchResults$.next(results),
                error: error => console.error(error), // TODO: Handle error and inform the user
            })
        );
    }

    ngOnDestroy(): void {
        this.subscriptions.forEach(sub => sub?.unsubscribe());
    }

    public isTextSearchable(text: string): boolean {
        return (
            text &&
            text.length >= 3 &&
            // if the text entered is like cu: make sure there are more text
            text.length - text.indexOf(":") > 3
        );
    }

    public hideDropdown() {
        this.dropdown.hide();
    }

    public onFilterChanged(changeEvent: MatSelectionListChange) {
        this.filter = changeEvent.options[0]?.value as FilterOption;
        this.input.nativeElement.select();
    }

    public onResultSelected(changeEvent: MatSelectionListChange) {
        this.searchText = "";

        if (changeEvent?.options?.length) {
            this.recordSelected.emit(changeEvent.options[0].value);
        }
    }

    private getSearchResults(searchText: string): Observable<ResultsPage> {
        const recordTypes = this.filter ? [this.filter.id] : undefined; //this.supportedFilters.map(f => f.id);

        return this.searchService.search(searchText, { recordTypes });
    }

    public showDropdown() {
        this.dropdown.show();
        this.input.nativeElement.select();
    }
}
