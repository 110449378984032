import { Injectable } from "@angular/core";
import { Actions, createEffect } from "@ngrx/effects";
import { map } from "rxjs/operators";

import { SessionService } from "@cloudextend/cefi/core";
import { onEvent } from "@cloudextend/common/events";

import { loggedOut } from "../logout";

import { userUpdated } from "./events";
import { LogRocketService } from "@cloudextend/common/core";

@Injectable()
export class UserIdentityEffects {
    constructor(
        private readonly actions$: Actions,
        private readonly logRocketService: LogRocketService,
        private readonly sessionService: SessionService
    ) { }

    updateIdenttyInCache$ = createEffect(
        () =>
            this.actions$.pipe(
                onEvent(userUpdated),
                map(event => {
                    this.sessionService.setIdentity(event.identity);
                    this.logRocketService.identify(event.identity.userId, {
                        email: event.identity.email,
                        name: event.identity.displayName,
                    });
                })
            ),
        { dispatch: false }
    );

    identifyUserInLogRocket$ = createEffect(
        () =>
            this.actions$.pipe(
                onEvent(userUpdated),
                map(event => {
                    this.logRocketService.identify(event.identity.userId, {
                        email: event.identity.email,
                        name: event.identity.displayName,
                    });
                })
            ),
        { dispatch: false }
    );

    removeIdenttyFromCache$ = createEffect(
        () =>
            this.actions$.pipe(
                onEvent(loggedOut),
                map(() => this.sessionService.removeIdentity())
            ),
        { dispatch: false }
    );
}
