// import { MemoizedSelector, DefaultProjectorFn } from "@ngrx/store";

import { Selector, Store } from "@ngrx/store";
import { concat, from, Observable, of } from "rxjs";
import { flatMap, map, take } from "rxjs/operators";

import { busy, idle } from "@cloudextend/common/core";
import { RxEvent } from "@cloudextend/common/events";
import { views } from "@cloudextend/common/state";

import { WorkflowContext, WorkflowStep } from "./workflow-step";
import { nextStep } from "./workflow.events";

export type WorkflowStepAction<
    ContextType extends WorkflowContext = WorkflowContext
> = (context: ContextType) => RxEvent | RxEvent[];
// eslint-disable-next-line @typescript-eslint/ban-types
export type WorkflowStepSelector = Selector<object, RxEvent | RxEvent[]>;

export type WorkflowStepSelectorFactory<
    ContextType extends WorkflowContext = WorkflowContext
> = (context: ContextType) => WorkflowStepSelector;

export abstract class Workflow<
    ContextType extends WorkflowContext = WorkflowContext
> {
    constructor(
        public readonly name: string,
        protected readonly store: Store
    ) {}

    public get onCompletion(): WorkflowStepAction<ContextType> {
        return () => views.home(this.name);
    }

    public abstract get steps(): WorkflowStep<ContextType>[];

    public get stepIndexByName() {
        return this.stepsMap ?? (this.stepsMap = this.createStepsMap());
    }

    protected stepsMap: Map<string, number> | undefined;

    protected await(
        name: string,
        waitingMessage: string,
        lengthyWork: (context: ContextType) => Observable<RxEvent>
    ): WorkflowStep<ContextType> {
        const activate = (context: ContextType) => {
            const busy$ = of(
                busy(this.name, {
                    message: waitingMessage,
                })
            );
            const idle$ = of(idle(this.name));

            return concat(busy$, lengthyWork(context), idle$);
        };

        return { name, activate };
    }

    protected do(
        name: string,
        fn: WorkflowStepAction<ContextType>
    ): WorkflowStep<ContextType> {
        const activate = (context: ContextType) => {
            const result = fn(context) ?? nextStep(this.name);
            return Array.isArray(result) ? from(result) : of(result);
        };

        return { name, activate };
    }

    protected select(
        name: string,
        selectorFactory: WorkflowStepSelectorFactory<ContextType>
    ): WorkflowStep<ContextType> {
        const activate = (context: ContextType) =>
            this.store.select(selectorFactory(context)).pipe(
                take(1),
                flatMap(selected =>
                    Array.isArray(selected) ? from(selected) : of(selected)
                )
            );

        return { name, activate };
    }

    protected createStepsMap(): Map<string, number> {
        const keyValuePairs = this.steps.map(
            (step, index) => [step.name, index] as [string, number]
        );
        return new Map<string, number>(keyValuePairs);
    }
}
