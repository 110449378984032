import { args, declareEvent } from "@cloudextend/common/events";

import { Association, MailItem } from "../../model";

export const associationsRequired = declareEvent(
    "message-context:associations:Required",
    args<{ item: MailItem }>()
);

export const associationsLoaded = declareEvent(
    "message-context:associations:Loaded",
    args<{ values: Association[] }>()
);

export const associationsCreated = declareEvent(
    "message-context:associations:Created",
    args<{ associations: Association[] }>()
);

export const associationsUpdated = declareEvent( //TODO review name
    "message-context:associations:Updated",
    args<{ value: Association[] }>()
);
