import { createSelector } from "@ngrx/store";

import { SubscriptionInfo } from "@cloudextend/cefi/core";
import { createEventReducer, when } from "@cloudextend/common/events";

import { selectUserState } from "../user-state";

import { subscriptionUpdated } from "./events";

export const STATE_KEY = "subscription";

export type State = SubscriptionInfo | undefined;

const initialState: State = undefined;

export const reducer = createEventReducer<State>(
    initialState,
    when(subscriptionUpdated, (_, event) => ({ ...event.subscription }))
);

const selectUserSubscription = createSelector(
    selectUserState,
    feature => (feature as { [STATE_KEY]: State })[STATE_KEY]
);

export const getSubscription = createSelector(
    selectUserSubscription,
    subscription => subscription
);

