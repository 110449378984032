import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { routerReducer, StoreRouterConnectingModule } from "@ngrx/router-store";
import { StoreModule } from "@ngrx/store";

import * as fromRouting from "./router-state";

@NgModule({
    declarations: [],
    imports: [
        CommonModule,
        StoreModule.forFeature(fromRouting.STATE_KEY, routerReducer),
        StoreRouterConnectingModule.forRoot({
            serializer: fromRouting.CustomSerializer,
        }),
    ],
})
export class RouterStateModule {}
