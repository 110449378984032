import { Session, SessionLifetime } from "@cloudextend/cefi/core";
import { args, declareEvent } from "@cloudextend/common/events";

export const sessionExpired = declareEvent("CEFI: Session Expired");
export const idTokenExpiring = declareEvent("CEFI: ID token expiring");
export const subscriptionExpired = declareEvent("CEFI: Subscription expired");
export const subscriptionNotFound = declareEvent("CEFI: Subscription not found");

export const sessionLifetimeUpdated = declareEvent(
    "CEFI/State/Session Lifetime Updated",
    args<{ lifetime: SessionLifetime }>()
);

export const sessionStarted = declareEvent(
    "CEFI:Session Started",
    args<{ session: Session }>()
);

export const sessionCleared = declareEvent(
    "CEFI:Session Cleared"
);
