import { RouterState } from "@angular/router";
import { RouterReducerState } from "@ngrx/router-store";
import { createFeatureSelector, createSelector } from "@ngrx/store";

import { LocationState } from "./location-state";

export * from "./custom-route-serializer";

export const STATE_KEY = "routing";
export * from "./location-state";
export interface State {
    [STATE_KEY]: RouterReducerState<LocationState>;
}

export const selectRouterState = createFeatureSelector<
    RouterReducerState<LocationState>
>(STATE_KEY);

export const getCurrentUrl = createSelector(
    selectRouterState,
    routerState => routerState?.state?.url
);

export const getCurrentView = createSelector(
    selectRouterState,
    routerState => routerState.state.view
);

export const getRouteData = createSelector(
    selectRouterState,
    routerState => routerState.state?.data
);

export const getQueryParams = createSelector(
    selectRouterState,
    routerState => routerState.state.queryParams
);
