import { Injectable } from "@angular/core";
import { createEffect, Actions } from "@ngrx/effects";
import { map, switchMap } from "rxjs/operators";

import { Logger, LogService } from "@cloudextend/common/core";
import { bindSource, onEvent } from "@cloudextend/common/events";
import { MessageContextEvents as events } from "@cloudextend/prp/core";
import { views } from "@cloudextend/prp/core";

const CONTEXT_ROUTING_EFFECTS = "Effects/ContextRouting";

const [
    noContext,
    messageRead,
    suggestionsRequired,
    associationsRequired,
] = bindSource(
    CONTEXT_ROUTING_EFFECTS,
    views.noContext,
    views.messageRead,
    events.suggestionsRequired,
    events.associationsRequired
);

@Injectable()
export class ContextRoutingEffects {
    constructor(private readonly actions$: Actions, logService: LogService) {
        this.logger = logService.createLogger(CONTEXT_ROUTING_EFFECTS);
    }

    private readonly logger: Logger;

    loadNoContext$ = createEffect(() =>
        this.actions$.pipe(
            onEvent(events.enteredNoContext),
            map(() => {
                this.logger.debug("Switching to no-context");
                return noContext();
            })
        )
    );

    loadReadContext$ = createEffect(() =>
        this.actions$.pipe(
            onEvent(events.enteredReadMode),
            switchMap(event => {
                this.logger.debug(
                    "Switching to message-read context for mail ID:",
                    event.item?.id
                );

                const item = event.item;

                return [
                    messageRead({ itemId: item.id }),
                    suggestionsRequired({ item }),
                    associationsRequired({ item }),
                ];
            })
        )
    );

    // TODO: Compose Mode
    // loadComposeContext$ = createEffect(() =>
    //     this.actions$.pipe(
    //         onEvent(events.enteredComposeMode),
    //         map(event => {
    //             this.logger.debug("Switching to message-compose context");
    //             // Do not wait for this navigation and continue to load the data
    //             this.router.goto(UiStates.mailCompose);

    //             return events.suggestionsRequired(
    //                 CONTEXT_ROUTING_EFFECTS,
    //                 event.item
    //             );
    //         })
    //     )
    // );
}
