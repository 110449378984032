import { Func, Predicate } from "./delegate-types";

export function findIndex<T>(
    array: T[],
    matcherFunction: Predicate<T>,
    startIndex = 0
): number {
    for (let i = startIndex; i < array.length; i++) {
        if (matcherFunction(array[i])) {
            return i;
        }
    }
    return -1;
}

export function findLastIndex<T>(
    array: T[],
    matcher: Predicate<T>,
    startIndex = 0
): number {
    for (let i = array.length - 1; i >= startIndex; i--) {
        if (matcher(array[i])) return i;
    }
    return -1;
}

export function findFirst<T>(
    array: T[],
    matcherFunction: Predicate<T>,
    startIndex = 0
): T | undefined {
    const index = findIndex(array, matcherFunction, startIndex);
    return index > -1 ? array[index] : undefined;
}

export function fill<T>(
    array: T[],
    fillValue: T,
    count: number = array.length,
    startIndex: number = 0
): T[] {
    const endIndex = startIndex + count;
    for (let i = endIndex - 1; i >= startIndex; i--) {
        array[i] = fillValue;
    }
    return array;
}

export function toMap<T, K>(array: T[], keyExtractor: Func<T, K>): Map<K, T> {
    const map = new Map<K, T>();
    array.reduce(
        (prv, current) => prv.set(keyExtractor(current), current),
        map
    );
    return map;
}
