import { ClientStorage } from "./client-storage";
import { StorageService } from "./storage.service";

export class AccountStorage implements ClientStorage {
    constructor(private _storageManager: StorageService) {}

    /** @deprecated Always use Save. `persist` parameter is now ignored. */
    set(key: string, value: string) {
        this.save(key, value);
    }

    save = (key: string, value: string) =>
        this._storageManager
            .getUnderlyingStorage()
            .setItem(this._storageManager.qualifyKey(key), value);

    get = (key: string): string | undefined =>
        this._storageManager
            .getUnderlyingStorage()
            .getItem(this._storageManager.qualifyKey(key)) || undefined;

    clear = () => {
        sessionStorage.clear();
        this._storageManager.getUnderlyingStorage().clear();
    };

    removeItem = (key: string) => {
        this._storageManager
            .getUnderlyingStorage()
            .removeItem(this._storageManager.qualifyKey(key));
    };
}
