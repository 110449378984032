import { Injectable } from "@angular/core";
import { CanActivate, Router, UrlTree } from "@angular/router";
import { Store } from "@ngrx/store";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";

import { takeOnce } from "@cloudextend/common/core";
import { messageContext } from "@cloudextend/prp/core";

@Injectable({
    providedIn: "root",
})
export class ContextualRouteGuard implements CanActivate {
    constructor(
        private readonly router: Router,
        private readonly store: Store
    ) {}

    private readonly currentMailItem$ = this.store.select(
        messageContext.getCurrentMailItemId
    );

    canActivate(): Observable<boolean | UrlTree> {
        return this.currentMailItem$.pipe(
            takeOnce(),
            map(mailItemId => {
                return mailItemId
                    ? this.router.createUrlTree(["message/read", mailItemId])
                    : this.router.createUrlTree(["no-context"]);
            })
        );
    }
}
