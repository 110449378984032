import { HttpErrorResponse } from "@angular/common/http";
import { throwError, Observable } from "rxjs";

import { isString } from "../utils/type-utils";

/**
 * 'remote' means either NetSuite or Salesforce
 * 'platform' means either Excel or GSuite (if applicable)
 */
export enum FaultSources {
    client = "client",
    api = "api",
    remote = "remote",
    platform = "platform",
}

export type FaultLike = string | Partial<Fault> | Error;

export interface Fault {
    title: string;
    source?: FaultSources;
    code?: number;
    detail?: string;
    isUserReadable?: boolean;
    id?: string;
    innerException?: unknown;
}

export function isFault(f: unknown): f is Fault {
    const faulty = f as Fault;
    return (
        faulty &&
        ((faulty.isUserReadable !== undefined && faulty.title !== undefined) ||
            faulty.code !== undefined ||
            faulty.detail !== undefined)
    );
}

export function fault(
    titleOrFault: FaultLike,
    codeOrOptions?: number | Partial<Fault>,
    detail?: string,
    source: FaultSources = FaultSources.client,
    isUserReadable: boolean = false
): Fault {
    let theFault: Fault;

    let code: number | undefined;
    let options: Partial<Fault>;

    if (typeof codeOrOptions === "object") {
        options = codeOrOptions;
        code = options.code;
    } else {
        code = codeOrOptions;
        options = {};
    }

    if (titleOrFault instanceof HttpErrorResponse) {
        const httpError = <HttpErrorResponse>titleOrFault;
        const error = httpError.error || {};

        let reason;
        if (error.reason) {
            const reasonObj = JSON.parse(error.reason);
            reason =
                reasonObj.error && reasonObj.error.message
                    ? reasonObj.error.message
                    : reason;
        }

        const isUserFacing =
            isUserReadable ||
            options.isUserReadable ||
            !!(httpError.error && httpError.error.reason); // Applies to NetSuite. Adjust for Sf
        theFault = {
            code: code || options.code || error.failureCode || httpError.status,
            detail:
                detail || options.detail || error.reason || httpError.message,
            id: "@" + Date.now().toString(),
            isUserReadable: isUserFacing,
            source: options.source || source || FaultSources.remote,
            title: options.title || reason || httpError.message,
        };
    } else if (titleOrFault instanceof Error) {
        theFault = {
            code: code || options.code,
            detail: detail || options.detail || titleOrFault.stack,
            id: "@" + Date.now().toString(),
            isUserReadable: isUserReadable || options.isUserReadable,
            source: options.source || source,
            title: options.title || titleOrFault.message,
        };
    } else if (isString(titleOrFault) || !titleOrFault) {
        theFault = {
            code: code || options.code,
            detail: detail || options.detail,
            id: "@" + Date.now().toString(),
            isUserReadable: isUserReadable || options.isUserReadable,
            source: source || options.source,
            title:
                <string>titleOrFault || "Something unexpectedly went wrong :(",
        };
    } else if (isFault(titleOrFault)) {
        theFault = titleOrFault;
    } else {
        // Some other object has been sent as an error
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const errObj = <any>titleOrFault;

        theFault = <Fault>{
            title:
                options.title || errObj.title || errObj.message || errObj.name,
            detail:
                options.detail ||
                detail ||
                errObj.detail ||
                errObj.description ||
                errObj.desc,
            id: options.id || "@" + Date.now().toString(),
            source: options.source || errObj.source || source,
            isUserReadable:
                isUserReadable ||
                options.isUserReadable ||
                errObj.isUserReadable,
            innerException: errObj,
        };
    }

    return theFault;
}

export function faultAsync(
    titleOrFault: string | Fault,
    codeOrOptions?: number | Partial<Fault>,
    detail?: string,
    source: FaultSources = FaultSources.client,
    isUserReadable: boolean = false
): Observable<never> {
    return throwError(
        fault(titleOrFault, codeOrOptions, detail, source, isUserReadable)
    );
}

export function rejection(
    titleOrFault: string | Fault,
    codeOrOptions?: number | Partial<Fault>,
    detail?: string,
    source: FaultSources = FaultSources.client,
    isUserReadable: boolean = false
) {
    return Promise.reject(
        fault(titleOrFault, codeOrOptions, detail, source, isUserReadable)
    );
}

/** Converts an unexpected Fault to a user-readable, user-reportable string. */
export function sayOops(faultOrError: FaultLike): string {
    const theFault = isFault(faultOrError) ? faultOrError : fault(faultOrError);

    return theFault.isUserReadable
        ? theFault.title
        : "Something went wrong. Retrying might help. Sorry, we don't have more information for you right now" +
              (theFault.id ? ` other than its ID: ${theFault.id}` : ".");
}
