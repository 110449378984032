<mat-list *ngIf="associations?.length; else noAssoc">
    <mat-divider></mat-divider>
    <mat-list-item *ngFor="let assoc of associations">
        <div class="user-icon-associated" matListAvatar></div>
        <div mat-line class="name">{{ assoc.record?.name }}</div>
        <div mat-line class="position">{{ assoc.record?.type }}</div>
        <mat-icon
            *ngIf="assoc.attachments"
            (click)="attachmentButtonClicked.emit(assoc)"
            >attach_file</mat-icon
        >
        <mat-divider></mat-divider>
    </mat-list-item>
    
</mat-list>

<ng-template #noAssoc>
    <p class="no-assoc-message">
        This email is not associated with any records.
    </p>
</ng-template>
