import { Component, ChangeDetectionStrategy } from "@angular/core";
import { Store } from "@ngrx/store";
import { filter } from "rxjs/operators";

import {
    ID_TOKEN_REFRESH_BUFFER_SECONDS,
    Session,
} from "@cloudextend/cefi/core";
import { getSession, events } from "@cloudextend/cefi/state";
import { takeOnce } from "@cloudextend/common/core";
import { events as workflows } from "@cloudextend/common/workflows";

const TOKEN_REFRSH_PAGE = "CEFI/UI/TokenRefreshPage";

@Component({
    selector: "cefi-token-refresh-page",
    templateUrl: "./token-refresh-page.component.html",
    styleUrls: ["./token-refresh-page.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TokenRefreshPageComponent {
    constructor(private readonly store: Store) {
        store.dispatch(events.idTokenExpiring(TOKEN_REFRSH_PAGE));
        // We are only asked to stay in this page
        store
            .select(getSession)
            .pipe(filter(this.isValidSession), takeOnce())
            .subscribe({
                next: () =>
                    this.store.dispatch(workflows.nextStep(TOKEN_REFRSH_PAGE)),
            });
    }

    private isValidSession(session: Session): boolean {
        const expiryTime = session?.lifetime?.idTokenExpiresInEpochSeconds ?? 0;
        return expiryTime - Date.now() / 1000 > ID_TOKEN_REFRESH_BUFFER_SECONDS;
    }
}
