import { Inject, Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";

import { CefiClient } from "@cloudextend/cefi/core";
import { Logger, LogService } from "@cloudextend/common/core";
import {
    GlobalSearchService,
    ECOSYSTEMCLIENT,
    ResultsPage,
    SearchResult,
    SearchOptions,
} from "@cloudextend/ecosystems/common";

@Injectable({
    providedIn: "root",
})
export class RestletSearchService extends GlobalSearchService {
    constructor(
        logService: LogService,
        @Inject(ECOSYSTEMCLIENT) private readonly apiClient: CefiClient
    ) {
        super();
        this.logger = logService.createLogger("BundleSearchService");
    }

    private readonly logger: Logger;

    public search(
        searchText: string,
        options: SearchOptions
    ): Observable<ResultsPage> {
        let relativePath = `/netsuite/records/v1?searchText=${searchText}`;
        if(options?.recordTypes?.length > 0){
            const recTypesParam = options.recordTypes.join(",");
            relativePath = `${relativePath}&records=${recTypesParam}`;
        }
        return this.apiClient
            .get<SearchResult[]>(relativePath)
            .pipe(map(records => ({ records })));
    }
}
