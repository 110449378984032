import { Directive, Input, TemplateRef, ViewContainerRef } from "@angular/core";

//
// https://stackoverflow.com/a/43172992
//

@Directive({
    selector: "[ceLet]",
})
export class LetDirective {
    @Input()
    set ceLet(context: unknown) {
        this.context.$implicit = this.context.ceLet = context;
        this.updateView();
    }

    context: { [key: string]: unknown } = {};

    constructor(
        private vcRef: ViewContainerRef,
        private templateRef: TemplateRef<unknown>
    ) {}

    updateView() {
        this.vcRef.clear();
        this.vcRef.createEmbeddedView(this.templateRef, this.context);
    }
}
