import { ChangeDetectionStrategy, Component, Input } from "@angular/core";

import { AutopilotStatus } from "@cloudextend/prp/core";

@Component({
    selector: "prp-autopilot-band",
    templateUrl: "./autopilot-band.component.html",
    styleUrls: ["./autopilot-band.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AutopilotBandComponent {
    @Input() autopilotStatus: AutopilotStatus;

    public readonly AutopilotStatus = AutopilotStatus;
}
