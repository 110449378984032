import { InjectionToken } from "@angular/core";

export interface Environment {
    apiBaseUrl: string;
    ecsBaseUrl: string; // TODO PRP-397
    logRocketAppId: string;
    intercomAppId: string;
    isProduction?: boolean;
}

export const ENV = new InjectionToken("CE-ENV");
