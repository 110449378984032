import { Injectable } from "@angular/core";
import { Actions, concatLatestFrom, createEffect } from "@ngrx/effects";
import { Store } from "@ngrx/store";
import { switchMap } from "rxjs/operators";

import { onEvent } from "@cloudextend/common/events";

import { suggestionAdded } from "../message-context/exports.events";
import {
    generateCandidateId,
    getCurrentMailItem,
} from "../message-context/exports.general";
import { CandidateSource } from "../model";
import * as views from "../views";

import { searchSuggestionAdded } from "./searched-suggestions.events";

@Injectable()
export class SearchEffects {
    constructor(
        private readonly actions$: Actions,
        private readonly store: Store
    ) {}

    updateSuggestionsOnSearch$ = createEffect(() =>
        this.actions$.pipe(
            onEvent(searchSuggestionAdded),
            concatLatestFrom(() => this.store.select(getCurrentMailItem)),
            switchMap(([event, mailItem]) => {
                const record = event.suggestion;
                const source = event.source + "->prp:core:search:Effects";
                const candidateId = generateCandidateId(record);
                return [
                    suggestionAdded(source, {
                        record,
                        suggestedReason: CandidateSource.explicitSearch,
                    }),
                    views.messageReadActions(
                        source,
                        { itemId: mailItem.id },
                        { suggest: candidateId }
                    ),
                ];
            })
        )
    );
}
