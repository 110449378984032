import { HttpClientModule } from "@angular/common/http";
import { NgModule } from "@angular/core";
import { MatIconModule } from "@angular/material/icon";
import { MatListModule } from "@angular/material/list";
import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { EffectsModule } from "@ngrx/effects";
import { StoreModule } from "@ngrx/store";
import { StoreDevtoolsModule } from "@ngrx/store-devtools";
import { LottieModule } from "ngx-lottie";

import { CefiStateModule } from "@cloudextend/cefi/state";
import { SubscriptionModule } from "@cloudextend/cefi/ui";
import {
    ECOSYSTEM,
    EnvironmentModule,
    HOST,
    MessagingModule,
} from "@cloudextend/common/core";
import { RouterStateModule } from "@cloudextend/common/routes";
import { BusyStateModule, CommonLayoutModule } from "@cloudextend/common/ui";
import { CommonWorkflowsModule } from "@cloudextend/common/workflows";
import { NetsuiteCommonModule } from "@cloudextend/ecosystems/netsuite/common";
import { EcosystemsNetsuitePingModule } from "@cloudextend/ecosystems/netsuite/ping";
import { RestletSearchModule } from "@cloudextend/ecosystems/netsuite/restlet-search";
import { PrpCoreModule } from "@cloudextend/prp/core";
import { PrpUiModule } from "@cloudextend/prp/ui";

import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { GmailCoreModule } from "./core/gmail-core.module";


export function playerFactory() {
    return import("lottie-web");
}

@NgModule({
    declarations: [AppComponent],
    imports: [
        // Angular Core Modules
        BrowserAnimationsModule,
        BrowserModule,
        HttpClientModule,
        // Angular Material
        MatListModule,
        MatIconModule,
        // CloudExtend Common Modules
        EnvironmentModule,
        AppRoutingModule,
        // App specific modules
        CommonLayoutModule,
        GmailCoreModule,
        SubscriptionModule,
        // Productivity Platform Modules
        BusyStateModule,
        CommonWorkflowsModule,
        MessagingModule,
        EcosystemsNetsuitePingModule,
        RestletSearchModule,
        PrpCoreModule,
        PrpUiModule,
        NetsuiteCommonModule,
        RestletSearchModule,
        // CEFI
        CefiStateModule,
        // Mocked Modules
        //PrpMockingModule,
        //MockMailboxModule,
        //MockSearchModule,
        // State Modules
        RouterStateModule,
        // NgRx Modules
        StoreModule.forRoot({}),
        EffectsModule.forRoot(),
        StoreDevtoolsModule.instrument({
            maxAge: 25,
            logOnly: false /* !localStorage.getItem(
                "cloudextend_internal_instrumentation"
            )*/, // TODO: When deploying to prod
        }),
        LottieModule.forRoot({ player: playerFactory }),
    ],
    providers: [
        {
            provide: ECOSYSTEM,
            useValue: { label: "NetSuite", name: "netsuite" },
        },
        {
            provide: HOST,
            useValue: { label: "Gmail", name: "google" },
        },
    ],
    bootstrap: [AppComponent],
})
export class AppModule { }
