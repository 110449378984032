import { UserIdentity } from "@cloudextend/cefi/core";
import { declareEvent, args } from "@cloudextend/common/events";

export const userUpdated = declareEvent(
    "CEFI:STATE:User updated",
    args<{ identity: UserIdentity }>()
);

// export const userLoggedIn = declareEvent(
//     "CEFI:STATE:User Logged In",
//     args<{ identity: UserIdentity }>()
// );
