import { Injectable } from "@angular/core";

import { Environment, EnvironmentService } from "./environment";
import { safeStringConvertion as safeStringifyUtil } from "./utils/string-utils";
import { UUID } from "./utils/uuid";

export type LogCall = (message: string, ...args: unknown[]) => void;

export type GuardedDebugLogCall = () => { message: string; args?: unknown[] };

export interface Logger {
    debug: LogCall;
    warn: LogCall;
    error: LogCall;
    info: LogCall;

    task: LogCall;

    log(fn: GuardedDebugLogCall): void;
}

export class ConsoleLogger implements Logger {
    private readonly _silentMode: boolean;

    private readonly _isDebugEnabled: boolean;

    constructor(private context: string) {
        const windowx = (window as unknown) as {
            disableLogging: boolean;
            celigoEnableDebugLogs: boolean;
        };
        this._silentMode = !!windowx.disableLogging;
        this._isDebugEnabled = !!windowx.celigoEnableDebugLogs;
    }

    /* eslint-disable no-console */
    debug = (message: string, ...args: unknown[]): void => {
        if (!this._silentMode) this.logExtended(console.debug, message, args);
    };
    warn = (message: string, ...args: unknown[]): void => {
        if (!this._silentMode) this.logExtended(console.warn, message, args);
    };
    error = (message: string, ...args: unknown[]): void => {
        if (!this._silentMode) this.logExtended(console.error, message, args);
    };
    info = (message: string, ...args: unknown[]): void => {
        if (!this._silentMode) this.logExtended(console.info, message, args);
    };
    /* eslint-enable no-console */

    // trace = (code: number, details?: any): void => (/*this._tracer.track(code, details)*/ );

    // logEvent = (code: number, details?: any): void => (/*this._tracer.done(code, details)*/);

    log = (fn: GuardedDebugLogCall): void => {
        if (!this._silentMode && this._isDebugEnabled) {
            const { message, args } = fn();
            this.debug(message, args);
        }
    };

    protected logExtended(
        loggingFn: (...data: unknown[]) => void,
        message: string,
        args: unknown[]
    ): void {
        if (loggingFn) {
            const contextualMessage = `[${this.context}] ${message}`;

            let details: unknown[];
            if (args.length) {
                details = [contextualMessage, ...args];
            } else {
                details = [contextualMessage];
            }

            return loggingFn.apply(window.console, details);
        }
    }

    public task = (message: string, ...args: unknown[]): void => {
        this.info(message, ...args);
    };

    private static safeStringify(args: unknown[]): string[] {
        return args.map(def => safeStringifyUtil(def));
    }
}

@Injectable({
    providedIn: "root",
})
export class LogService {
    constructor(env: EnvironmentService) {
        // TODO: Remove tracer (as we have logRocket)?
        const tracer = this.getTracer();
        if (!tracer) {
            if (!env.isUnitTesting) {
                console.warn(
                    "'tracer' unavailable. Logs will be limited to the console."
                );
            }
            return;
        }

        const environment = env.getEnvironment<Environment>();
        const url = environment.apiBaseUrl + this.logUrl;

        tracer.configs({
            url,
            queryParams: {
                requestToken: UUID.generate(6, 8),
                source: this.tracerSource,
            },
            // TODO
            // userApp: environment.appName,
        });

        tracer.enable("streaming");
        tracer.enable("archiving");
    }

    private readonly logUrl = "/api/v2/events";
    private readonly tracerSource = "tracer";

    public enableDebug = (enable: boolean) =>
        (((window as unknown) as {
            celigoEnableDebugLogs: boolean;
        }).celigoEnableDebugLogs = enable);

    public createLogger(obj: string | unknown): Logger {
        const loggerName =
            typeof obj === "string"
                ? obj
                : (obj as { name: string }).name || String(obj);
        return new ConsoleLogger(loggerName);
    }

    public refreshCredentials(): void {
        const tracer = this.getTracer();
        const headers = {};
        // TODO:
        // headers["Authorization"] = this.http.getAuthorizationHeader();
        // headers["x-celigo-ns-account-prefs"] = this.http.getAccountHeader();
        // headers["x-celigo-license"] = this.http.getLicenseHeader();
        if (tracer) tracer.configs({ headers });
    }

    private getTracer() {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        return (<any>window).tracer;
    }
}
