<h3 mat-dialog-title class="attachment-title">
    Add Attachments for "{{ data.targetRecordName }}"
</h3>
<mat-dialog-content>
    <div class="check-all-files" *ngIf="data.availableAttachments.length">
        <mat-checkbox
            [checked]="
                data.selectedAttachments?.length ===
                data.availableAttachments.length
            "
            (change)="
                $event.checked ? filesList.selectAll() : filesList.deselectAll()
            "
            >All
        </mat-checkbox>
    </div>

    <mat-divider></mat-divider>

    <mat-selection-list [(ngModel)]="data.selectedAttachments" #filesList>
        <mat-list-option
            *ngFor="let attachment of data.availableAttachments"
            [value]="attachment"
            title="{{ attachment.name }}"
        >
            <div class="attachment-circle" matListAvatar></div>
            <div mat-line class="file-name">
                {{ attachment.name }}
            </div>
            <div mat-line class="file-size">
                {{ attachment?.fileSizeBytes }}
            </div>
        </mat-list-option>
    </mat-selection-list>
</mat-dialog-content>
<mat-dialog-actions class="attachment-actions">
    <!-- prettier-ignore -->
    <mat-slide-toggle
        [(ngModel)]="data.includeInlineImages"
        [labelPosition]="'before'"
        class="include-inline"
        >
        Include Inline Images
    </mat-slide-toggle>
    <button
        mat-button
        color="primary"
        [mat-dialog-close]="data"
        [disabled]="
            !data.selectedAttachments?.length && !data.includeInlineImages
        "
    >
        ADD ATTACHMENTS
    </button>
</mat-dialog-actions>
