export class NonStorage implements Storage {
    private constructor() {
        // Do nothing.
    }

    public static instance = new NonStorage();

    [name: string]: unknown;

    get length() {
        return 0;
    }

    getItem(): string | null {
        return null;
    }

    key(): string | null {
        return null;
    }

    setItem(): void {
        // Do nothing
    }

    clear(): void {
        // Do nothing
    }

    removeItem(): void {
        // Do nothing
    }
}
