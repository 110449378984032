import { NgModule } from "@angular/core";
import { EffectsModule } from "@ngrx/effects";
import { StoreModule } from "@ngrx/store";

import { ConnectionEffects } from "./connection/effects";
import { UserIdentityEffects } from "./identity";
import {
    SessionLifetimeEffects,
    TokenRefreshEffects,
} from "./session-lifetime";
import { UserSubscriptionEffects } from "./subscription";
import { USER_STATE_FEATURE } from "./user-state";
import { reducers } from "./user-state.reducer";


@NgModule({
    imports: [
        EffectsModule.forFeature([
            ConnectionEffects,
            SessionLifetimeEffects,
            UserSubscriptionEffects,
            TokenRefreshEffects,
            UserIdentityEffects,
        ]),
        StoreModule.forFeature(USER_STATE_FEATURE, reducers),
    ],
})
export class CefiStateModule {}
