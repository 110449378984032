import { createSelector } from "@ngrx/store";

import { createEventReducer, when } from "@cloudextend/common/events";

import { MailItem } from "../../model";
import { mailItemLoaded } from "../message-context.effects";
import { selectContext } from "../message-context.shared";

export const STATE_KEY = "mail-item";

export interface State {
    item?: MailItem;
}

const initialState: State = {};

export const reducer = createEventReducer(
    initialState,
    when(mailItemLoaded, (state, event) => ({
        ...state,
        item: { ...event.item },
    }))
);

export const getCurrentMailItem = createSelector(
    selectContext,
    (state: { [STATE_KEY]: State }) => state[STATE_KEY].item
);

export const getCurrentMailItemId = createSelector(
    getCurrentMailItem,
    mailItem => mailItem?.id
);

export const getHasActiveContext = createSelector(
    getCurrentMailItem,
    mailItem => !!mailItem
);

export const getAvailableAttachments = createSelector(
    getCurrentMailItem,
    mailItem => mailItem.attachments || []
);

export const getHasAttachments = createSelector(
    getCurrentMailItem,
    mailItem => !!mailItem
);
