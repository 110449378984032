import { createSelector } from "@ngrx/store";

import { Connection } from "@cloudextend/cefi/core";
import { createEventReducer, when } from "@cloudextend/common/events";

import { selectUserState } from "../user-state";

import { connectionLoaded, connectionUpdated } from "./events";

export const STATE_KEY = "connection";

export type State = Connection | undefined;

const initialState: State = undefined;

export const reducer = createEventReducer<State>(
    initialState,
    when(connectionLoaded, (state, event) => ({ ...event.connection })),
    when(connectionUpdated, (state, event) => ({ ...event.connection }))
);

const selectConnectionsState = createSelector(
    selectUserState,
    state => (state as { [STATE_KEY]: State })[STATE_KEY]
);

export const hasActiveConnection = createSelector(
    selectConnectionsState,
    connection => !!connection
);

export const getActiveConnection = createSelector(
    selectConnectionsState,
    connection => connection
);

export const getConnectedAccount = createSelector(
    getActiveConnection,
    connection => connection?.account
);
