export interface Uri {
    isAbsolutePath: boolean;
    hasLeadingSeperator: boolean;
    hasQueryString: boolean;
    hasTrailingSeperator: boolean;
    path: string;
}

export function getUri(path: string): Uri {
    let isAbsolutePath = false;
    let hasQueryString = false;
    const hasTrailingSeperator = path.charAt(path.length - 1) === "/";
    const hasLeadingSeperator = path.charAt(0) === "/";

    for (let i = 0; i < path.length; i++) {
        if (path.charAt(i) === "?") {
            hasQueryString = true;
            // This is the last piece of information we had to extract by iterating.
            break;
        } else if (
            !hasLeadingSeperator &&
            i < 6 /* https: */ &&
            path.charAt(i) === ":" &&
            path.charAt(i + 1) === "/" &&
            path.charAt(i + 2) === "/"
        ) {
            isAbsolutePath = true;
        }
    }

    return {
        isAbsolutePath,
        hasLeadingSeperator,
        hasQueryString,
        hasTrailingSeperator,
        path,
    };
}
