import { Injectable } from "@angular/core";
import { Observable, of, throwError } from "rxjs";
import { catchError, flatMap, map } from "rxjs/operators";

import {
    Ecosystem,
    Environment,
    EnvironmentService,
    fault,
    LocalStorageService,
    Logger,
    LogService,
    ServerResponse,
} from "@cloudextend/common/core";

import { CefiClient } from "../net";

import { Connection, isValidConnection } from "./connection";
import { ConnectionResponse } from "./connection-response";

export const ACTIVE_CONNECTION_STORAGE_KEY = "cefi.connection.active";

@Injectable({
    providedIn: "root",
})
export class ConnectionsService {
    constructor(
        private readonly api: CefiClient,
        private readonly storageService: LocalStorageService,
        envService: EnvironmentService,
        logService: LogService
    ) {
        this.environment = envService.getEnvironment();
        this.logger = logService.createLogger("ConnectionService");
    }

    private readonly environment: Environment;
    private readonly logger: Logger;

    public getActiveConnection(ecosystem: Ecosystem): Connection | undefined {
        const storedCnxJson = this.storageService.get(
            `${ACTIVE_CONNECTION_STORAGE_KEY}.${ecosystem.name}`
        );
        if (!storedCnxJson) return undefined;

        try {
            const storedConnection = JSON.parse(storedCnxJson);
            if (isValidConnection(storedConnection)) {
                return storedConnection;
            } else {
                return undefined;
            }
        } catch (err) {
            this.logger.error("Error loading active connection", err);
            return undefined;
        }
    }

    public getEncryptedConnection(
        ecosystem: Ecosystem,
        connectionId: string
    ): Observable<string> {
        return this.api
            .get<ServerResponse>(
                `/cefi/connections/${ecosystem.name}/v1/${connectionId}/key`
            )
            .pipe(
                catchError(error => {
                    this.logger.error(
                        "Error while fetching the encryptedConection.",
                        error
                    );
                    return throwError(error);
                }),
                flatMap((response: ServerResponse) => {
                    if (!response.isSuccess) {
                        const message = `Error while fetching the encryptedConection for ${connectionId}`;
                        this.logger.error(message);
                        return throwError(response.error);
                    }
                    return of(response.data as string);
                })
            );
    }

    public getAvailableConnections(
        ecosystem: Ecosystem
        // TODO: Accept a fields list array and use it build the URL. Reqruires that we've updated
        // ConnectionResponse interface to match ServerResponse properly.
    ): Observable<Connection[]> {
        return this.api
            .get<ConnectionResponse>(
                `/cefi/connections/${ecosystem.name}/v1?fields=account`
            )
            .pipe(
                map(response => {
                    if (!response.isSuccess) {
                        this.logger.error(
                            "Error while fetching connections.",
                            response.error
                        );
                        return [];
                    }

                    return response?.data ?? [];
                }),
                catchError(failure => {
                    this.logger.error(
                        "Error while fetching connections.",
                        failure
                    );
                    return of([]);
                })
            );
    }

    public saveActiveConnection(
        ecosystem: Ecosystem,
        connection: Connection
    ): void {
        if (!isValidConnection(connection)) {
            throw fault("Attempted to save an invalid connection");
        }

        try {
            this.storageService.save(
                `${ACTIVE_CONNECTION_STORAGE_KEY}.${ecosystem.name}`,
                JSON.stringify(connection)
            );
        } catch (error) {
            this.logger.error(
                "There was an error storing the active connection",
                error
            );
        }
    }

    public removeFromCache(ecosystem: Ecosystem): void {
        try {
            this.storageService.removeItem(
                `${ACTIVE_CONNECTION_STORAGE_KEY}.${ecosystem.name}`
            );
        } catch (error) {
            this.logger.error(
                "There was an error removinng the active connection",
                error
            );
        }
    }
}
