import { createSelector, ActionReducer, ActionReducerMap } from "@ngrx/store";

import { occurenceOf, RxEvent } from "@cloudextend/common/events";

import * as fromAssociations from "./associations/associations.reducer";
import * as fromCandidates from "./candidates";
import * as fromMailItem from "./mail-item/mail-item.reducer";
import * as Events from "./message-context.events";
import { selectContext } from "./message-context.shared";

export { Events };

export interface State {
    [fromAssociations.STATE_KEY]: fromAssociations.State;
    [fromMailItem.STATE_KEY]: fromMailItem.State;
    [fromCandidates.STATE_KEY]: fromCandidates.State;
}

export const reducers: ActionReducerMap<State, RxEvent> = {
    [fromAssociations.STATE_KEY]: fromAssociations.reducer,
    [fromMailItem.STATE_KEY]: fromMailItem.reducer,
    [fromCandidates.STATE_KEY]: fromCandidates.reducer,
};

export const getHasActiveContext = createSelector(
    selectContext,
    (state: State) => state
);

export function contextChangeMetaReducer(
    reducer: ActionReducer<State, RxEvent>
) {
    return (state: State, event: RxEvent) => {
        return occurenceOf(Events.contextChanged, event)
            ? reducer(undefined, event)
            : reducer(state, event);
    };
}
