/**
 * @deprecated Use `value !== undefined` instead`
 */
export function isDefined(value: unknown) {
    // Counter-intuitive: Following is faster than doing `value !== undefined`
    // https://www.measurethat.net/Benchmarks/Show/9316/0/value-undefined-vs-typeof-value-undefined-when-value-is
    // Node.js Utils recommends simply calling `value == undefined`. Given an overhead of
    // a method call, that is probably sound advice. Hence the deprecation.
    return typeof value !== "undefined";
}

// eslint-disable-next-line @typescript-eslint/ban-types
export function isFunction(value: unknown): value is Function {
    return typeof value === "function";
}

export function isString(value: unknown): value is string {
    return typeof value === "string";
}

// eslint-disable-next-line @typescript-eslint/ban-types
export function isObject(value: unknown): value is Object {
    return value !== null && typeof value === "object";
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function isNumber(n: any): n is number {
    return !isNaN(n) && isFinite(n) && !isString(n) && !Array.isArray(n);
}
