import {
    ChangeDetectionStrategy,
    Component,
    Input,
    ViewChild,
} from "@angular/core";
import { MatSidenav } from "@angular/material/sidenav";
import { createSelector, Store } from "@ngrx/store";

import {
    getConnectedAccount,
    getUserDisplayName,
} from "@cloudextend/cefi/state";
import { getRouteData } from "@cloudextend/common/routes";
import { views } from "@cloudextend/common/state";

import { CustomLayoutData } from "../custom-layout";

export const getBannerOptions = createSelector(
    getRouteData,
    (data: CustomLayoutData) => data?.layout?.bannerOptions
);

@Component({
    selector: "cloudextend-app-layout",
    templateUrl: "./app-layout.component.html",
    styleUrls: ["./app-layout.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppLayoutComponent {
    constructor(private readonly store: Store) {}

    @Input() productTag: string;

    @ViewChild("mainMenu") mainMenu: MatSidenav;

    public bannerOptions$ = this.store.select(getBannerOptions);
    public userDisplayName$ = this.store.select(getUserDisplayName);
    public accountNumber$ = this.store.select(getConnectedAccount);

    public backButtonClicked() {
        this.store.dispatch(views.home("@AppLayout"));
    }

    public toggleMenu() {
        this.mainMenu.toggle();
    }
}
