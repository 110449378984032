import { Inject, Injectable } from "@angular/core";
import { Actions, createEffect } from "@ngrx/effects";
import { Store } from "@ngrx/store";
import { Observable, of, throwError, timer } from "rxjs";
import { catchError, flatMap, map, switchMap } from "rxjs/operators";

import {
    CefiClient,
    SessionLifetime,
    TokenLifetime,
} from "@cloudextend/cefi/core";
import {
    ENV,
    Environment,
    fault,
    Logger,
    LogService,
    ServerResponse,
    takeOnce,
} from "@cloudextend/common/core";
import { onEvent } from "@cloudextend/common/events";

import {
    idTokenExpiring,
    sessionExpired,
    sessionLifetimeUpdated,
    sessionStarted,
} from "../events";

type TokenLifeTimeResponse = ServerResponse<TokenLifetime>;

const TOKEN_REFRESH_EFFECT = "CEFI/State/TokenRefreshEffects";
const REFRESH_INTERVAL_SECONDS = 3600; // One hour

@Injectable()
export class TokenRefreshEffects {
    constructor(
        private readonly actions$: Actions,
        private readonly api: CefiClient,
        @Inject(ENV) private readonly environment: Environment,
        private readonly store: Store,
        logService: LogService
    ) {
        this.logger = logService.createLogger("TokenRefreshEffects");
    }

    private readonly logger: Logger;

    refreshTokenBeforeExpiry$ = createEffect(() =>
        this.actions$.pipe(
            onEvent(idTokenExpiring),
            switchMap(() => this.getRefreshedToken()),
            map(refreshed => {
                this.queueTokenRefresh(refreshed.idTokenExpiresInSeconds);

                const epochNow = Math.floor(Date.now() / 1000);
                const lifetime = {
                    idTokenExpiresInEpochSeconds:
                        epochNow + refreshed.idTokenExpiresInSeconds,
                    refreshTokenExpiresInEpochSeconds:
                        epochNow + refreshed.refreshTokenExpiresInSeconds,
                } as SessionLifetime;

                return sessionLifetimeUpdated(TOKEN_REFRESH_EFFECT, {
                    lifetime,
                });
            }),
            catchError(error => {
                this.logger.error("Error while refreshing ID token", error);
                return of(sessionExpired(TOKEN_REFRESH_EFFECT));
            })
        )
    );

    queueRefreshOnSessionStart$ = createEffect(
        () =>
            this.actions$.pipe(
                onEvent(sessionStarted),
                map(() => this.queueTokenRefresh(REFRESH_INTERVAL_SECONDS))
            ),
        { dispatch: false }
    );

    private getRefreshedToken(): Observable<TokenLifetime> {
        return this.api
            .post<TokenLifeTimeResponse>("/cefi/auth/v1/refresh", "{}")
            .pipe(
                flatMap(response => {
                    if (!response || (!response.isSuccess && !response.error)) {
                        return throwError(
                            fault(
                                "Unspecified error while refreshing the token"
                            )
                        );
                    } else if (!response.isSuccess) {
                        return throwError(response.error);
                    }
                    return of(response.data);
                })
            );
    }

    public queueTokenRefresh(dueInSeconds: number) {
        this.logger.debug(
            "Starting refresh token timer for seconds:",
            dueInSeconds
        );

        // set a timeout to refresh the token 10 minute before it expires
        const timeout = (dueInSeconds - 600) * 1000;

        timer(timeout)
            .pipe(takeOnce())
            .subscribe(() =>
                this.store.dispatch(idTokenExpiring(TOKEN_REFRESH_EFFECT))
            );
    }
}
