import { InjectionToken } from "@angular/core";

export const ECOSYSTEM = new InjectionToken("ECOSYSTEM");
export interface Ecosystem {
    name: string;
    label: string;
}

export enum EcosystemEnvironment {
    production,
    sandbox,
    any,
}

export const HOST = new InjectionToken("HOST");
export interface Host {
    name: string;
    label: string;
}
