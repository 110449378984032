import { RecordRef } from "@cloudextend/common/core";

import { Candidate } from "./candidate";
import { FileAttachment } from "./file-attachment";
import { MailItem } from "./mail-item";

export const enum AssociationStatus {
    preExisting = 0,
    newlySelected = 1,
}

export interface Association {
    readonly mailItem: MailItem;
    readonly record: RecordRef;
    readonly author?: Candidate;
    readonly recipient?: Candidate;
    status?: AssociationStatus;
    attachments?: FileAttachment[];
    url?: string;
}

export function associationsComparer(
    lhs: Pick<Association, "record" | "status">,
    rhs: Pick<Association, "record" | "status">
): number {
    if (!lhs?.record && !rhs?.record) return 0;
    if (lhs?.record && !rhs?.record) return 1;
    if (!lhs?.record && rhs?.record) return -1;

    const statusComp = (lhs.status || 0) - (rhs.status || 0);
    // prettier-ignore
    const nameComp = statusComp !== 0 
        ? statusComp 
        : lhs.record.name?.localeCompare(rhs.record.name);
    return nameComp || 0;
}
