import { createView, where } from "@cloudextend/common/routes";

export const noContext = createView("no-context");

export const messageRead = createView(
    "message/read/:itemId",
    where<{ itemId: string }>()
);

export const messageReadActions = createView(
    "message/read/:itemId/actions",
    where<{ itemId: string }>()
);

export const noBundle = createView("no-bundle");
