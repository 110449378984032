export interface RecordRef {
    id?: string;
    type: string;
    name: string;
    recordGroup?: string;
}

export function recordsComparer(lhs: RecordRef, rhs: RecordRef): boolean {
    return lhs?.id === rhs?.id && lhs?.type === rhs?.type;
}
