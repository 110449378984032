import { Inject, Injectable } from "@angular/core";
import { Observable, throwError } from "rxjs";
import { catchError, map } from "rxjs/operators";

import { CefiClient } from "@cloudextend/cefi/core";
import { Logger, LogService, ServerResponse } from "@cloudextend/common/core";
import { PingService, ECOSYSTEMCLIENT } from "@cloudextend/ecosystems/common";

@Injectable({
    providedIn: "root",
})
export class BundlePingService extends PingService {
    constructor(
        logService: LogService,
        @Inject(ECOSYSTEMCLIENT) private readonly apiClient: CefiClient
    ) {
        super();
        this.logger = logService.createLogger("BundlePingService");
    }
    private readonly logger: Logger;

    public ping(): Observable<boolean> {
        const relativePath = "/netsuite/ping/v1";
        return this.apiClient.get<ServerResponse>(relativePath).pipe(
            catchError(error => {
                this.logger.error(
                    "Error while fetching the bundle state.",
                    error
                );
                return throwError(error);
            }),
            map(res => res.isSuccess)
        );
    }
}
