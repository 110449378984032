import { Component, EventEmitter, Input, Output } from "@angular/core";

@Component({
    selector: "cloudextend-main-menu",
    templateUrl: "./main-menu.component.html",
    styleUrls: ["./main-menu.component.scss"],
})
export class MainMenuComponent {
    @Output() menuActionCompleted = new EventEmitter<void>();
    @Output() hidden = new EventEmitter<void>();

    @Input() userDisplayName: string;
    @Input() accountNumber: string;

    public getUserInitials(displayName: string): string {
        if (!displayName) return "Hi!";

        const names = displayName.split(" ");
        return names.length < 2
            ? displayName.charAt(0)
            : names[0].charAt(0) + names[names.length - 1].charAt(0);
    }
}
