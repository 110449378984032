import { Component, OnDestroy } from "@angular/core";
import { Observable, Subject } from "rxjs";

@Component({ template: "<div><div>" })
export class BaseComponent implements OnDestroy {
    private readonly destroyedEvent = new Subject<void>();

    protected get destroyed(): Observable<void> {
        return this.destroyedEvent;
    }

    ngOnDestroy(): void {
        this.destroyedEvent.next();
        this.destroyedEvent.complete();
    }
}
