import { RouterStateSnapshot } from "@angular/router";
import { RouterStateSerializer } from "@ngrx/router-store";

import { View } from "../view-state";
import { ViewStateData } from "../view-state/view-state-data";

import { LocationState } from "./location-state";

export class CustomSerializer implements RouterStateSerializer<LocationState> {
    serialize(routerState: RouterStateSnapshot): LocationState {
        let route = routerState.root;

        // Get to the leaf node:
        while (route.firstChild) {
            route = route.firstChild;
        }

        const {
            url,
            root: { queryParams },
        } = routerState;

        const { data, params } = route;

        const viewStateData = data as ViewStateData;
        const view: View = {
            stateName: viewStateData.viewState?.stateName ?? url,
        };

        return { data, params, queryParams, url, view };
    }
}
