import { Inject, Injectable } from "@angular/core";
import { Observable, throwError } from "rxjs";
import { catchError, map } from "rxjs/operators";

import { CefiClient, SessionService } from "@cloudextend/cefi/core";
import {
    ECOSYSTEM,
    Ecosystem,
    Logger,
    LogService,
    RecordRef,
    ServerResponse,
    takeOnce,
} from "@cloudextend/common/core";
import { ECOSYSTEMCLIENT } from "@cloudextend/ecosystems/common";

import {
    Association,
    AssociationRequest,
    MailItem,
    AssociationHistoryItem,
    ItemStatus,
} from "../model";

@Injectable({
    providedIn: "root",
})
export class AssociationsService {
    private readonly logger: Logger;

    constructor(
        private readonly sessionSvc: SessionService,
        @Inject(ECOSYSTEM) private readonly ecosystem: Ecosystem,
        @Inject(ECOSYSTEMCLIENT) private readonly apiClient: CefiClient,
        logService: LogService
    ) {
        this.logger = logService.createLogger("AssociationsService");
    }

    private buildAssociations(response: ServerResponse): Association[] {
        const associationHistory = response.data as AssociationHistoryItem[];

        //TODO remove status filtering and use following for the status log
        //TODO show failures under Associated Records?
        // const associations: Association[] = associationHistory.map(item => {
        //         const assoc: Association = {
        //             mailItem: item.mailItem,
        //             record: item.record,
        //             status: item.status
        //         };
        //         return assoc;
        // });

        const associations: Association[] = associationHistory.reduce(
            (filtered, item) => {
                if (item.status === ItemStatus.success) {
                    const assoc: Association = {
                        mailItem: item.mailItem,
                        record: item.record,
                    };
                    filtered.push(assoc);
                }
                return filtered;
            },
            []
        );
        return associations;
    }

    public fetchAssociations(mailItem: MailItem): Observable<Association[]> {
        const { identity } = this.sessionSvc.readFromCache();
        const relativePath = `/associations/netsuite/v1/messages?globalId=${mailItem.id}-${identity.email}`;

        return this.apiClient.get<ServerResponse>(relativePath).pipe(
            catchError(error => {
                return throwError(error);
            }),
            map(response => {
                if (!response.isSuccess) {
                    this.logger.error(
                        "Failed to fetch associations of the given email."
                    );
                    return [];
                }
                return this.buildAssociations(response);
            })
        );
    }

    public saveAssociations(
        associations: Association[]
    ): Observable<Association[]> {
        const { mailItem, author, recipient } = associations[0];
        const records: RecordRef[] = associations.map(
            association => association.record
        );
        let ccEmails = mailItem.recipientsTo.map(item => item.email);
        if (recipient) {
            ccEmails = ccEmails.filter(email => email !== recipient.email);
        }
        const { identity } = this.sessionSvc.readFromCache();

        const request: AssociationRequest = {
            messageId: mailItem.id,
            globalId: `${mailItem.id}-${identity.email}`,
            userEmail: identity.email,
            fromEmail: mailItem.sender.email,
            toEmail: recipient?.email || identity.email,
            ccEmails: ccEmails,
            subject: mailItem.subject,
            message: mailItem.body,
            dateTime: mailItem.timestamp,
            associations: records,
            author: author || undefined,
            recipient: recipient || undefined,
        };
        const relativePath = `/associations/${this.ecosystem.name}/v1/messages`;
        return this.apiClient
            .post<ServerResponse>(relativePath, JSON.stringify(request))
            .pipe(
                takeOnce(),
                catchError(error => {
                    this.logger.error(
                        "Error while saving email to the selected records!",
                        error
                    );
                    return throwError(error);
                }),
                map(response => {
                    let createdAssociations = [];
                    if (
                        response.data &&
                        Array.isArray(response.data) &&
                        response.data.length === associations.length
                    ) {
                        //response data preserves the array element order within the request
                        createdAssociations = [...associations].filter(
                            (association, index) => {
                                if (response.data[index].statusCode === 200) {
                                    return association;
                                }
                            }
                        );
                    }
                    return createdAssociations;
                })
            );
    }
}
