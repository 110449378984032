import { ErrorHandler, Injectable } from "@angular/core";

import { CommonErrorCodes } from "../constants/common-error-codes";
import { Logger, LogService } from "../log.service";
import { NotificationService, NoticeSeverity } from "../notifications";
import { safeStringConvertion } from "../utils";
import { findFirst } from "../utils/array-utils";
import { isString } from "../utils/type-utils";

import { Fault, FaultLike, FaultSources, isFault } from "./fault";

export interface ErrorFormatter {
    format(error: FaultLike): Fault;
    isKnownError(error: FaultLike): boolean;
}

const RETRY_MESSAGE =
    "Something seems to be wrong. Please retry what you were doing to see if it's only a temporary snag.";

@Injectable({
    providedIn: "root",
})
export class ExceptionManager implements ErrorHandler {
    constructor(
        private readonly notifier: NotificationService,
        logService: LogService
    ) {
        this.errorCodeHandlers = new Map<string, ErrorFormatter>();
        this.handlerChain = [];

        this.logger = logService.createLogger("ExceptionManager");
    }

    private readonly errorCodeHandlers: Map<string, ErrorFormatter>;
    private readonly handlerChain: ErrorFormatter[];
    private readonly logger: Logger;

    // Global Error Handler entry point
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    handleError(error: any) {
        this.logger.error("Unexpected error", error);
        const userfacingError = this.getUserReadableError(error);
        this.notifier.send({
            message: userfacingError.title,
            severity: NoticeSeverity.error,
        });
    }

    registerFormatter = (
        formatter: ErrorFormatter,
        errorCode?: string
    ): void => {
        if (errorCode) {
            this.errorCodeHandlers.set(errorCode, formatter);
        } else {
            this.handlerChain.push(formatter);
        }
    };

    handleAsync = (
        error: string | Partial<Fault>,
        source: FaultSources = FaultSources.client
    ) => Promise.reject(this.getUserReadableError(error, source));

    getUserReadableError = (
        error: string | Partial<Fault>,
        source: FaultSources = FaultSources.client
    ): Fault => {
        let readableError: Fault;
        let formatter: ErrorFormatter | undefined;

        const fault = isFault(error) ? error : undefined;
        const faultCode = fault ? "" + fault.code : undefined;

        if (fault && fault.isUserReadable) {
            readableError = fault;
        } else if (
            fault &&
            faultCode &&
            (formatter = this.errorCodeHandlers.get(faultCode))
        ) {
            readableError = formatter.format(fault);
        } else if (
            error &&
            (formatter = findFirst(
                this.handlerChain,
                f => f?.isKnownError(error) ?? false
            ))
        ) {
            readableError = formatter.format(error);
        } else if (isString(error)) {
            readableError = {
                source,
                title: error,
                code: CommonErrorCodes.unknownError,
                isUserReadable: true,
                detail: error,
            };
        } else if (fault || (error as Error)?.message) {
            // Possible fault object
            readableError = {
                title: error.title ? error.title : (error as Error).message,
                code: error.code || CommonErrorCodes.unknownError,
                detail: safeStringConvertion(error),
                isUserReadable: error.source === FaultSources.remote,
                source: error.source ? error.source : source,
            };
        } else {
            readableError = this.getBasicReadableError(error);
        }

        if (!readableError.title) readableError.title = RETRY_MESSAGE;

        return readableError;
    };

    private getBasicReadableError(
        error: Partial<Fault>,
        source = FaultSources.client
    ): Fault {
        return {
            title: RETRY_MESSAGE,
            code: CommonErrorCodes.unknownError,
            isUserReadable: true,
            detail: safeStringConvertion(error),
            source: error?.source || source,
        };
    }
}
