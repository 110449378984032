import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { Observable } from "rxjs";

import { ENV, Environment } from "@cloudextend/common/core";

@Injectable({
    providedIn: "root",
})
export class CefiClient {
    constructor(
        protected readonly http: HttpClient,
        @Inject(ENV) protected readonly environment: Environment
    ) {}

    public get<ResponseType>(relativePath: string): Observable<ResponseType> {
        const headers = this.createHeaders();

        return this.http.get<ResponseType>(
            `${this.environment.apiBaseUrl}${relativePath}`,
            { headers: headers, withCredentials: true }
        );
    }

    public post<ResponseType>(
        relativePath: string,
        body: string
    ): Observable<ResponseType> {
        let headers = this.createHeaders();

        const token = this.getXsrfToken();
        if (token) {
            headers = headers.append("x-ce-xsrf-token", token);
        }

        return this.http.post<ResponseType>(
            `${this.environment.apiBaseUrl}${relativePath}`,
            body,
            { headers, withCredentials: true }
        );
    }

    protected createHeaders(
        additionalHeaders?: Record<string, string | string[]>
    ): HttpHeaders {
        const standardHeaders = {
            "Content-Type": "application/json; charset=UTF-8",
        };

        if (additionalHeaders) {
            return new HttpHeaders({
                ...standardHeaders,
                ...additionalHeaders,
            });
        } else {
            return new HttpHeaders(standardHeaders);
        }
    }

    protected getXsrfToken() {
        const match = document.cookie.match(
            new RegExp("(^|\\s)xsrf-token=([^;]+)")
        );
        if (match) return match[2];
        return undefined;
    }
}
