import { createEntityAdapter, EntityState } from "@ngrx/entity";
import { createSelector } from "@ngrx/store";

import { createEventReducer, when } from "@cloudextend/common/events";

import { associationsComparer, Association } from "../../model";
import { selectContext } from "../message-context.shared";

import { associationsLoaded, associationsUpdated } from "./associations.events";

export const STATE_KEY = "associations";

export interface State extends EntityState<Association> {
    currentSelectionId: string;
    wasLoaded?: boolean;
}

const adatper = createEntityAdapter<Association>({
    // none of the followig null checks should ever be needed. Simply
    // including them to be vigilent.
    selectId: assoc =>
        !assoc.record
            ? `unkown:${Date.now}`
            : `${assoc.record.type}:${assoc.record.id || Date.now}`,
    sortComparer: associationsComparer,
});

export const initialState = adatper.getInitialState({
    currentSelectionId: undefined,
}) as State;

export const reducer = createEventReducer(
    initialState,
    when(associationsLoaded, (state, event) =>
        adatper.setAll(event.values, { ...state, wasLoaded: true })
    ),
    when(associationsUpdated, (state, event) =>
        adatper.addMany(event.value, state)
    )
);

const { selectAll, selectEntities } = adatper.getSelectors();

const selectAssociationsState = createSelector(
    selectContext,
    (state: { [STATE_KEY]: State }) => state[STATE_KEY]
);

export const getAllAssociations = createSelector(
    selectAssociationsState,
    selectAll
);

export const getAssociationsMap = createSelector(
    selectAssociationsState,
    selectEntities
);

export const getWasAssociationsLoaded = createSelector(
    selectAssociationsState,
    state => state.wasLoaded
);
