import { Route } from "@angular/router";

import { ButtonStyle, BannerOptions } from "@cloudextend/common/ui";

import { ConnectionsListPageComponent } from "./connections-list-page/connections-list-page.component";
import { TokenRefreshPageComponent } from "./token-refresh-page/token-refresh-page.component";

// TODO: Use Component Lazy Loading at some point (https://johnpapa.net/angular-9-lazy-loading-components/)
export const routes: Route[] = [
    {
        path: "connections",
        component: ConnectionsListPageComponent,
        data: {
            layout: {
                bannerOptions: {
                    buttonStyle: ButtonStyle.back,
                    hideSearchBox: true,
                    title: "Connections",
                } as BannerOptions,
            },
        },
    },
    {
        path: "refresh-token",
        component: TokenRefreshPageComponent,
        data: {
            layout: {
                bannerOptions: {
                    buttonStyle: ButtonStyle.back,
                    hideSearchBox: true,
                } as BannerOptions,
            },
        },
    },
];
