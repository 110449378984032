import { Observable } from "rxjs";

export abstract class Flyout {
    taskCompleted!: Observable<void>;
}

export abstract class FlyoutContent extends Flyout {
    title!: string;
    contentChanged?: Observable<void>;
    preventClosing?: boolean;
}
