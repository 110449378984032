import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";

import { ENV } from "./environment";
import { EnvironmentService } from "./environment.service";

@NgModule({
    declarations: [],
    imports: [CommonModule],
    providers: [
        {
            provide: ENV,
            useFactory: (envSvc: EnvironmentService) => envSvc.getEnvironment(),
            deps: [EnvironmentService],
        },
    ],
})
export class EnvironmentModule {}
