import {
    ChangeDetectionStrategy,
    Component,
    Input,
    OnDestroy,
    OnInit,
} from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { Router } from "@angular/router";
import { Update } from "@ngrx/entity";
import { Store } from "@ngrx/store";
import { filter, flatMap, map, takeUntil } from "rxjs/operators";

import { takeOnce } from "@cloudextend/common/core";
import { getQueryParams } from "@cloudextend/common/routes";
import { BaseComponent } from "@cloudextend/common/ui";
import {
    Association,
    AssociationStatus,
    FileAttachment,
    AssociatedAttachment,
    messageContext as context,
    MessageContext as Context,
    MessageContextEvents as ContextEvents,
} from "@cloudextend/prp/core";
import { Candidate } from "@cloudextend/prp/core";

import * as Preferences from "../../preferences";
import {
    AttachmentsPickerComponent,
    PickerParameters,
} from "../attachments-picker";

const MESSAGE_CONTEXT_PAGE = "Pages/Message Context";

@Component({
    selector: "prp-message-context-page",
    templateUrl: "./message-context-page.component.html",
    styleUrls: ["./message-context-page.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MessageContextPageComponent
    extends BaseComponent
    implements OnInit {
    constructor(
        private readonly dialog: MatDialog,
        private readonly router: Router,
        private readonly store: Store
    ) {
        super();
    }

    @Input()
    public selectedItems: Candidate[] = [];

    public readonly suggestions$ = this.store.select(
        Context.getAvailableRecords
    );

    public readonly associations$ = this.store.select(
        Context.getAllAssociations
    );

    public readonly hasAttachments$ = this.store.select(
        Context.getHasAttachments
    );

    public readonly skipWelcome$ = this.store.select(
        Preferences.getSkipWelcome
    );

    public readonly wasSuggestionsLoaded$ = this.store.select(
        Context.getWasSuggestionsLoaded
    );

    public readonly wasAssociationsLoaded$ = this.store.select(
        Context.getWasAssociationsLoaded
    );

    private readonly availableAttachments$ = this.store.select(
        Context.getAvailableAttachments
    );

    public ngOnInit() {
        this.store.dispatch(
            Context.events.contextualViewLoaded(MESSAGE_CONTEXT_PAGE)
        );

        this.store
            .select(getQueryParams)
            .pipe(
                takeUntil(this.destroyed),
                map(params => params["suggest"]),
                filter(candidateId => !!candidateId),
                flatMap(candidateId =>
                    this.store.select(context.getCandidate(candidateId))
                ),
                filter(candidate => !!candidate)
            )
            .subscribe(candidate => {
                // Since the candidate may already exist as a suggestion,
                // we should force it to be treated as one that the user
                // searched for
                this.selectedItems = [...this.selectedItems, candidate];
            });
    }

    public toggleSkipWelcome(): void {
        this.store.dispatch(
            Preferences.skipWelcomeToggled(MESSAGE_CONTEXT_PAGE)
        );
    }

    public onAttachmentButtonClick(record: Candidate): void {
        this.availableAttachments$
            .pipe(
                takeOnce(),
                flatMap(availableAttachments =>
                    this.showAttachmentPicker(record, availableAttachments)
                )
            )
            .subscribe((attachment: AssociatedAttachment) => {
                const update = {
                    id: `${record.type}:${record.id}`,
                    changes: {
                        includeInlineImages: attachment?.includeInlineImages,
                        selectedAttachments: attachment?.selectedAttachments,
                    },
                } as Update<Candidate>;
                this.store.dispatch(
                    ContextEvents.candidateUpdated(MESSAGE_CONTEXT_PAGE, {
                        update,
                    })
                );
            });
    }

    public onSaveAssociations(): void {
        this.store
            .select(Context.getAssocatiationContext)
            .pipe(
                takeOnce(),
                map(associationContext =>
                    this.selectedItems.map(
                        candidate =>
                            ({
                                ...associationContext,
                                record: candidate,
                                status: AssociationStatus.newlySelected,
                            } as Association)
                    )
                )
            )
            .subscribe(associations => {
                this.store.dispatch(
                    ContextEvents.associationsCreated(MESSAGE_CONTEXT_PAGE, {
                        associations,
                    })
                );
                this.selectedItems = [];
            });
    }

    private showAttachmentPicker = (
        record: Candidate,
        availableAttachments: FileAttachment[]
    ) => {
        const dialogRef = this.dialog.open(AttachmentsPickerComponent, {
            width: "200em",
            data: this.getAttachmetsPickerParams(record, availableAttachments),
        });

        const dialogClosed = dialogRef.afterClosed();
        this.router.events
            .pipe(takeUntil(dialogClosed))
            .subscribe(() => dialogRef.close());

        return dialogClosed;
    };

    private getAttachmetsPickerParams = (
        record: Candidate,
        availableAttachments: FileAttachment[]
    ) =>
        ({
            availableAttachments,
            selectedAttachments: record.selectedAttachments
                ? [...record.selectedAttachments]
                : undefined,
            targetRecordName: record.name,
        } as PickerParameters);
}
