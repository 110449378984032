import { Injectable } from "@angular/core";

import { ClientStorage } from "./client-storage";
import { NonStorage } from "./non-storage";

@Injectable({ providedIn: "root" })
export class LocalStorageService implements ClientStorage {
    constructor() {
        this.underlyingStorage = localStorage
            ? localStorage
            : NonStorage.instance;
    }

    private readonly underlyingStorage: Storage;

    /** @deprecated Use Save */
    set(key: string, value: string): void {
        this.save(key, value);
    }

    save(key: string, value: string): void {
        this.underlyingStorage.setItem(key, value);
    }

    get(key: string): string | undefined {
        return this.underlyingStorage.getItem(key) ?? undefined;
    }

    clear(): void {
        this.underlyingStorage.clear();
    }

    removeItem(key: string): void {
        this.underlyingStorage.removeItem(key);
    }
}
