import { CommonModule } from "@angular/common";
import { ErrorHandler, NgModule } from "@angular/core";

import { NotificationsModule } from "../notifications";

import { ExceptionManager } from "./exception-manager.service";

@NgModule({
    declarations: [],
    imports: [CommonModule, NotificationsModule],
    providers: [{ provide: ErrorHandler, useClass: ExceptionManager }],
})
export class ExceptionManagementModule {
    constructor() {
        if (++ExceptionManagementModule.activationCouner > 1) {
            console.warn(
                "ExceptoinManagerModule was imported more than once. " +
                    "Only import the module in the root application."
            );
        }
    }

    private static activationCouner = 0;
}
