import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { EffectsModule } from "@ngrx/effects";

import { MessageContextModule } from "./message-context/message-context.module";
import { PrpInitializerEffects } from "./prp-init.effects";
import { SearchEffects as SearchedSuggestionsEffects } from "./search/searched-suggestions.effects";

@NgModule({
    imports: [
        CommonModule,
        EffectsModule.forFeature([
            PrpInitializerEffects,
            SearchedSuggestionsEffects,
        ]),
        MessageContextModule,
    ],
})
export class PrpCoreModule {}
