import { Injectable } from "@angular/core";
import { Actions, createEffect } from "@ngrx/effects";
import { map } from "rxjs/operators";

import { SessionService } from "@cloudextend/cefi/core";
import { onEvent } from "@cloudextend/common/events";

import { loggedOut } from "../logout";

import { sessionLifetimeUpdated } from "./events";
import { TokenRefreshEffects } from "./token-refresh/token-refresh.effect";

export { TokenRefreshEffects };

@Injectable()
export class SessionLifetimeEffects {
    constructor(
        private readonly actions$: Actions,
        private readonly sessionService: SessionService
    ) {}

    updateLifetimeInCache$ = createEffect(
        () =>
            this.actions$.pipe(
                onEvent(sessionLifetimeUpdated),
                map(event => this.sessionService.setLifetime(event.lifetime))
            ),
        { dispatch: false }
    );

    removeLifetimeFromCache$ = createEffect(
        () =>
            this.actions$.pipe(
                onEvent(loggedOut),
                map(_ => this.sessionService.removeLifetime())
            ),
        { dispatch: false }
    );
}
