import { Inject, Injectable } from "@angular/core";
import { Actions, createEffect } from "@ngrx/effects";
import { map } from "rxjs/operators";

import { ConnectionsService } from "@cloudextend/cefi/core";
import { Ecosystem, ECOSYSTEM } from "@cloudextend/common/core";
import { onEvent } from "@cloudextend/common/events";

import { loggedOut } from "../logout";

import { connectionUpdated } from "./events";

@Injectable()
export class ConnectionEffects {
    constructor(
        private readonly actions$: Actions,
        private readonly connectionSvc: ConnectionsService,
        @Inject(ECOSYSTEM) private readonly ecosystem: Ecosystem
    ) {}

    saveOnUpdate$ = createEffect(
        () =>
            this.actions$.pipe(
                onEvent(connectionUpdated),
                map(updatedEvent => {
                    this.connectionSvc.saveActiveConnection(
                        this.ecosystem,
                        updatedEvent.connection
                    );
                })
            ),
        { dispatch: false }
    );

    removeOnLogout$ = createEffect(
        () =>
            this.actions$.pipe(
                onEvent(loggedOut),
                map(_ => {
                    this.connectionSvc.removeFromCache(
                        this.ecosystem
                    );
                })
            ),
        { dispatch: false }
    );
}
