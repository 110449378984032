<mat-sidenav-container class="app-container" fullscreen>
    <mat-sidenav #mainMenu class="app-sidenav" mode="over">
        <cloudextend-main-menu
            [userDisplayName]="userDisplayName$ | async"
            [accountNumber]="accountNumber$ | async"
            (menuActionCompleted)="mainMenu.toggle()"
        >
            <ng-content select="[ce-role=mainMenu]"></ng-content>
        </cloudextend-main-menu>
    </mat-sidenav>

    <mat-sidenav-content class="app-content">
        <cloudextend-app-banner
            [productTag]="productTag"
            [options]="bannerOptions$ | async"
            (menuButtonClicked)="toggleMenu()"
            (backButtonClicked)="backButtonClicked()"
        >
            <ng-content select="[ce-role=searchbox]"></ng-content>
        </cloudextend-app-banner>

        <main class="content">
            <router-outlet></router-outlet>
        </main>

        <footer fxFlexOffset="auto">
            <router-outlet name="footer"></router-outlet>
            <div class="help-bubble" id="need_help_icon">
                <span class="icon">
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="22.786"
                        height="26.06"
                    >
                        <path
                            d="M2.373.012h18.822s1.615-.3 1.589 2.033V26.06l-6.715-3.168H2.373s-1.836-.128-2.21-1.668 0-19.178 0-19.178S1.083.03 2.373.012z"
                            fill="#fff"
                        />
                        <path
                            d="M3.924 15.649c2.348 1.539 5.107 2.332 7.914 2.275a12.96 12.96 0 0 0 7.265-2.275"
                            fill="none"
                            stroke="rgba(0,0,0,0.3)"
                            stroke-width="2"
                        />
                    </svg>
                </span>
                <span class="text">Need Help?</span>
            </div>
        </footer>
    </mat-sidenav-content>
</mat-sidenav-container>
