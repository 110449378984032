import { RecordRef } from "@cloudextend/common/core";

import { FileAttachment } from "./file-attachment";

export enum CandidateSource {
    newlyCreated = 0,
    explicitSearch = 1,
    suggestion = 2,
}

export interface Candidate extends RecordRef {
    source: CandidateSource;
    selectedAttachments?: FileAttachment[];
    includeInlineImages?: boolean;
    email?: string;
    selectedAt?: number;
}

export function candidateComparer(
    lhs: Pick<Candidate, "name" | "source" | "selectedAt">,
    rhs: Pick<Candidate, "name" | "source" | "selectedAt">
): number {
    const defaultSrc = CandidateSource.suggestion;
    const sourceComp = (lhs.source ?? defaultSrc) - (rhs.source ?? defaultSrc);
    if (sourceComp !== 0) {
        return sourceComp;
    } else if (lhs.source === defaultSrc) {
        return lhs.name?.localeCompare(rhs.name);
    } else {
        return rhs.selectedAt - lhs.selectedAt;
    }
}
