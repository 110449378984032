import { Injectable } from "@angular/core";
import { createEffect, Actions } from "@ngrx/effects";
import { map } from "rxjs/operators";

import { onEvent } from "@cloudextend/common/events";

import { Logger, LogService } from "../log.service";
import { NotificationService } from "../notifications/notification.service";

import * as NotificationsEvents from "./notifications.events";

@Injectable()
export class NotificationsEffects {
    constructor(
        private readonly actions$: Actions,
        private readonly notificationsSvc: NotificationService,
        logService: LogService
    ) {
        this.logger = logService.createLogger("NotificationsEffects");
    }

    private readonly logger: Logger;

    showNotification$ = createEffect(() =>
        this.actions$.pipe(
            onEvent(NotificationsEvents.notify),
            map((event) => {
                return this.notificationsSvc.send(event.notice);
            })
    ), { dispatch: false });
}
