import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { EffectsModule } from "@ngrx/effects";
import { Store, StoreModule } from "@ngrx/store";

import { PreferencesEffects } from "./preferences.effects";
import { moduleLoaded } from "./preferences.events";
import { featureKey, reducer } from "./preferences.reducer";

@NgModule({
    declarations: [],
    imports: [
        CommonModule,
        StoreModule.forFeature(featureKey, reducer),
        EffectsModule.forFeature([PreferencesEffects]),
    ],
})
export class PreferencesModule {
    constructor(store: Store) {
        store.dispatch(moduleLoaded("Preferences/Module"));
    }
}
