import { enableProdMode } from "@angular/core";
import { platformBrowserDynamic } from "@angular/platform-browser-dynamic";

import { AppModule } from "./app/app.module";

if (!location.host.startsWith("localhost:")) { enableProdMode(); }

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch(err => console.error(err));
