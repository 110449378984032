import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core"

import { ECOSYSTEMCLIENT } from "@cloudextend/ecosystems/common";

import { NetsuiteClientService } from "../lib/netsuite-client.service";


@NgModule({
    imports: [CommonModule],
    providers :[{ provide: ECOSYSTEMCLIENT, useClass: NetsuiteClientService }]
})
export class NetsuiteCommonModule {}
