import { Inject, Injectable } from "@angular/core";
import {
    ActivatedRouteSnapshot,
    CanActivate,
    Router,
    UrlTree,
} from "@angular/router";

import { Ecosystem, ECOSYSTEM } from "@cloudextend/common/core";

@Injectable({
    providedIn: "root",
})
export class EcosystemRedirector implements CanActivate {
    constructor(
        @Inject(ECOSYSTEM) private readonly ecosystem: Ecosystem,
        private readonly router: Router
    ) {}

    canActivate(route: ActivatedRouteSnapshot): UrlTree {
        return this.router.createUrlTree([
            ...route.url.map(u => u.path),
            this.ecosystem.name,
        ]);
    }
}
