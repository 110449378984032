import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { RouterModule } from "@angular/router";

import { routes } from "./routes";
import { InitializationWorkflow } from "./workflow/initialization-workflow";

@NgModule({
    imports: [
        CommonModule,
        RouterModule.forChild(routes)
    ],
    providers: [InitializationWorkflow],
})
export class CefiRoutesModule {}
