import { Injectable } from "@angular/core";
import { Actions, createEffect } from "@ngrx/effects";
import { switchMap } from "rxjs/operators";

import { args, declareEvent, onEvent } from "@cloudextend/common/events";

import { ContextType, MailItem } from "../model";

import {
    contextChanged,
    enteredComposeMode,
    enteredNoContext,
    enteredReadMode,
} from "./message-context.events";

const CONTEXT_EFFECTS = "Effects/MessageContext";

export const mailItemLoaded = declareEvent(
    "Mail Item Loaded",
    args<{ item: MailItem }>()
);

@Injectable()
export class MessageContextEffects {
    constructor(private readonly actions$: Actions) {}

    changeContext$ = createEffect(() =>
        this.actions$.pipe(
            onEvent(contextChanged),
            switchMap(event => {
                const item = event.item;

                switch (event.contextType) {
                    case ContextType.messageCompose:
                        return [
                            mailItemLoaded(CONTEXT_EFFECTS, { item }),
                            enteredComposeMode(CONTEXT_EFFECTS, { item }),
                        ];
                    case ContextType.messageRead:
                        return [
                            mailItemLoaded(CONTEXT_EFFECTS, { item }),
                            enteredReadMode(CONTEXT_EFFECTS, { item }),
                        ];
                    default:
                        return [enteredNoContext(CONTEXT_EFFECTS)];
                }
            })
        )
    );
}
