import { Component, EventEmitter, Input, Output } from "@angular/core";

@Component({
    selector: "cloudextend-flyout-toolbar",
    templateUrl: "./flyout-toolbar.component.html",
    styleUrls: ["./flyout-toolbar.component.scss"],
})
export class FlyoutToolbarComponent {
    @Output() preventClosing: boolean;
    @Output() flyoutClosed: EventEmitter<void> = new EventEmitter<void>();

    @Input() flyoutTitle: string;
}
