import { Component, Input } from "@angular/core";

@Component({
    selector: "cloudextend-list-shimmer",
    templateUrl: "./list-shimmer.component.html",
    styleUrls: ["./list-shimmer.component.scss"],
})
export class ListShimmerComponent {
    public virtualItems = [0];

    get virtualItemsCount() {
        return this.virtualItems.length;
    }

    @Input() set virtualItemsCount(value: number) {
        this.virtualItems = new Array(value).fill(0, 0, -1);
    }
}
