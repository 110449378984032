import { Component } from "@angular/core";

import { HistoryService } from "../history";

import { BaseComponent } from "./component-base.component";

@Component({ template: "<div><div>" })
export class BaseAppComponent extends BaseComponent {
    constructor(historyService: HistoryService) {
        super();
        historyService.disableBackNavigation(this.destroyed);
    }
}
