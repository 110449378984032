import { Func } from "../utils/delegate-types";

import { MemoryStorage } from "./memory-storage";

export class BestEffortPersistentStorage implements Storage {
    private _fallbackStore = new MemoryStorage();
    private _lastActiveStorage: Storage | undefined;

    [k: string]: unknown;

    clear = () => this.attempt(s => s?.clear());

    getItem = (key: string): string | null =>
        this.attempt(s => s?.getItem(key)) || null;

    setItem = (key: string, data: string): void =>
        this.attempt(s => s?.setItem(key, data));

    get length(): number {
        return this.attempt(s => s?.length || 0);
    }

    key = (index: number): string | null =>
        this.attempt(s => s?.key(index)) ?? null;

    removeItem = (key: string): void => this.attempt(s => s?.removeItem(key));

    private attempt = <T>(fn: Func<Storage, T>): T => {
        const [result, lastActiveStorage] = this.tryStorage(
            fn,
            this._lastActiveStorage || localStorage,
            sessionStorage
        );
        this._lastActiveStorage = lastActiveStorage;

        return result;
    };

    private tryStorage = <T>(
        fn: Func<Storage, T>,
        primary: Storage,
        fallback?: Storage
    ): [T, Storage] => {
        try {
            return [fn(primary), primary];
        } catch (err) {
            return fallback
                ? this.tryStorage(fn, fallback)
                : [fn(this._fallbackStore), this._fallbackStore];
        }
    };
}
