import { Injectable } from "@angular/core";

import {
    fault,
    isNumber,
    LocalStorageService,
    Logger,
    LogService,
} from "@cloudextend/common/core";

import { UserIdentity } from "../model";

import { Session, SessionLifetime } from "./session";

export const SESSION_LIFETIME_STORAGE_KEY = "cefi.session.lifetime";
export const SESSION_IDENTITY_STORAGE_KEY = "cefi.session.identity";

@Injectable({ providedIn: "root" })
export class SessionService {
    constructor(
        private readonly storageSvc: LocalStorageService,
        logSevice: LogService
    ) {
        this.logger = logSevice.createLogger("SessionService");
    }

    private readonly logger: Logger;

    private isValidLifetime(lifetime: SessionLifetime): boolean {
        return (
            lifetime &&
            isNumber(lifetime.idTokenExpiresInEpochSeconds) &&
            isNumber(lifetime.refreshTokenExpiresInEpochSeconds)
        );
    }

    private isValidIdentity(identity: UserIdentity): boolean {
        const isValid =
            identity?.email && identity?.displayName && identity?.userId;
        return !!isValid;
    }

    private parseLifetime(lifetimeJson: string): SessionLifetime | undefined {
        if (!lifetimeJson) return undefined;

        const parsedLifetime = JSON.parse(lifetimeJson) as SessionLifetime;
        return this.isValidLifetime(parsedLifetime)
            ? parsedLifetime
            : undefined;
    }

    private parseIdentity(identityJson: string): UserIdentity | undefined {
        if (!identityJson) return undefined;

        const parsedIdentity = JSON.parse(identityJson) as UserIdentity;
        return this.isValidIdentity(parsedIdentity)
            ? parsedIdentity
            : undefined;
    }

    public readFromCache(): Session {
        const expiredLifetime = {
            idTokenExpiresInEpochSeconds: 0,
            refreshTokenExpiresInEpochSeconds: 0,
        } as SessionLifetime;

        try {
            const lifetimeJson = this.storageSvc.get(
                SESSION_LIFETIME_STORAGE_KEY
            );
            if (!lifetimeJson) return { lifetime: expiredLifetime };

            const identityJson = this.storageSvc.get(
                SESSION_IDENTITY_STORAGE_KEY
            );

            const lifetime =
                this.parseLifetime(lifetimeJson) || expiredLifetime;
            const identity = identityJson
                ? this.parseIdentity(identityJson)
                : undefined;

            return { lifetime, identity };
        } catch (err) {
            this.logger.error("Error loading session from cache.", err);
            return { lifetime: expiredLifetime };
        }
    }

    public setLifetime(lifetime: SessionLifetime): void {
        if (this.isValidLifetime(lifetime)) {
            const lifetimeJson = JSON.stringify(lifetime);
            this.storageSvc.save(SESSION_LIFETIME_STORAGE_KEY, lifetimeJson);
        } else {
            this.logger.warn("Specified lifetime is invalid", lifetime);
            throw fault(
                "There was an error storing the session lifetime in the cache."
            );
        }
    }

    public removeLifetime(): void {
        try {
            this.storageSvc.removeItem(SESSION_LIFETIME_STORAGE_KEY);
        } catch (err) {
            this.logger.error("Error removing session from the cache.", err);
            throw fault(
                "There was an error removing the session lifetime from the cache."
            );
        }
    }

    public setIdentity(identity: UserIdentity): void {
        if (this.isValidIdentity(identity)) {
            const identityJson = JSON.stringify(identity);
            this.storageSvc.save(SESSION_IDENTITY_STORAGE_KEY, identityJson);
        } else {
            this.logger.error("Specified identity is invalid.", identity);
            throw fault(
                "There was an error storing the user identity in the cache."
            );
        }
    }

    public removeIdentity(): void {
        try {
            this.storageSvc.removeItem(SESSION_IDENTITY_STORAGE_KEY);
        } catch (err) {
            this.logger.error("Error removing identity from the cache.", err);
            throw fault(
                "There was an error removing the identity from the cache."
            );
        }
    }
}
