import { createSelector } from "@ngrx/store";

import { UserIdentity } from "@cloudextend/cefi/core";
import { createEventReducer, when } from "@cloudextend/common/events";

import { selectUserState } from "../user-state";

import { userUpdated } from "./events";

export const STATE_KEY = "identity";

export type State = UserIdentity | undefined;

const initialState: State = undefined;

export const reducer = createEventReducer<State>(
    initialState,
    when(userUpdated, (_, event) => ({ ...event.identity }))
);

const selectUserIdentity = createSelector(
    selectUserState,
    feature => (feature as { [STATE_KEY]: State })[STATE_KEY]
);

export const getUserDisplayName = createSelector(
    selectUserIdentity,
    identity => identity?.displayName
);

export const isLoggedIn = createSelector(
    selectUserIdentity,
    identity => !!identity
);
