import { createEntityAdapter, EntityState } from "@ngrx/entity";
import { createSelector } from "@ngrx/store";

import { RecordRef } from "@cloudextend/common/core";
import { createEventReducer, when } from "@cloudextend/common/events";

import { CandidateSource, Candidate, candidateComparer } from "../../model";
import { getCurrentMailItem } from "../mail-item/mail-item.reducer";
import { selectContext } from "../message-context.shared";

import { candidateUpdated } from "./candidates.events";
import { suggestionAdded, suggestionsLoaded } from "./suggestions.events";

export const STATE_KEY = "candidates";

export interface State extends EntityState<Candidate> {
    wereSuggestionsLoaded?: boolean;
}

export function generateCandidateId(candidate: RecordRef) {
    return candidate.id?.indexOf(":") > -1
        ? candidate.id
        : candidate.type + ":" + candidate.id;
}

const adapter = createEntityAdapter<Candidate>({
    selectId: generateCandidateId,
});

export const initialState = adapter.getInitialState();

export const reducer = createEventReducer(
    initialState,
    when(suggestionsLoaded, (state, event) => {
        const updatedState = adapter.removeMany(
            r => r.source === CandidateSource.suggestion,
            state
        );
        return adapter.addMany(event.values, {
            ...updatedState,
            wereSuggestionsLoaded: true,
        });
    }),
    when(candidateUpdated, (state, event) =>
        adapter.updateOne(event.update, state)
    ),
    when(suggestionAdded, (state, event) => {
        const candidate = {
            ...event.record,
            source: event.suggestedReason,
            selectedAt: Date.now(),
        } as Candidate;
        return adapter.upsertOne(candidate, state);
    })
);

const selectCandidatesState = createSelector(
    selectContext,
    (state: { [STATE_KEY]: State }) => state[STATE_KEY]
);

const { selectAll, selectEntities } = adapter.getSelectors();

const getAllCandidates = createSelector(selectCandidatesState, selectAll);

export const getCandidateRecords = createSelector(
    getAllCandidates,
    candidates => candidates.sort(candidateComparer)
);

export const getCandidate = (id: string) =>
    createSelector(
        selectCandidatesState,
        selectEntities,
        entities => entities.entities[id]
    );

export const getWasSuggestionsLoaded = createSelector(
    selectCandidatesState,
    state => !!state.wereSuggestionsLoaded
);

const getToEmails = createSelector(getCurrentMailItem, mailItem =>
    mailItem.recipientsTo.map(c => c.email)
);

const getCandidatesWithEmails = createSelector(
    getCandidateRecords,
    candidates =>
        candidates.filter(c => c["email"]) as (Candidate & { email: string })[]
);

const getMatchingAuthorCandidate = createSelector(
    getCandidatesWithEmails,
    getCurrentMailItem,
    (candidates, mailItem) => {
        const order = ["employee", "contact"]; //TODO Meta data service to fetch order
        return candidates
            .sort((a, b) => {
                //TODO sort within getCandidatesWithEmails?
                return order.indexOf(a.type) - order.indexOf(b.type);
            })
            .find(candidate => candidate.email === mailItem.sender.email);
    }
);

const getMatchingRecipientCandidate = createSelector(
    getCandidatesWithEmails,
    getToEmails,
    (candidates, toEmails) => {
        let recipient: Candidate;
        const order = ["employee", "contact"]; //TODO Meta data service to fetch order

        candidates.sort((a, b) => {
            return order.indexOf(a.type) - order.indexOf(b.type);
        });

        for (const email of toEmails) {
            recipient = candidates.find(candidate => candidate.email === email);
            if (recipient) break;
        }
        return recipient;
    }
);

export const getAssocatiationContext = createSelector(
    getCurrentMailItem,
    getMatchingAuthorCandidate,
    getMatchingRecipientCandidate,
    (mailItem, author, recipient) => {
        return { mailItem, author, recipient };
    }
);
