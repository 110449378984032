import { Inject, Injectable } from "@angular/core";
import {
    ActivatedRouteSnapshot,
    CanActivate,
    Router,
    UrlTree,
} from "@angular/router";

import { Host, HOST } from "@cloudextend/common/core";

@Injectable({
    providedIn: "root",
})
export class PlatformRedirector implements CanActivate {
    constructor(
        @Inject(HOST) private readonly host: Host,
        private readonly router: Router
    ) {}

    canActivate(route: ActivatedRouteSnapshot): UrlTree {
        return this.router.createUrlTree([
            ...route.url.map(u => u.path),
            this.host.name,
        ]);
    }
}
