// import { Route } from "@angular/router";

import { Route } from "@angular/router";

import { EcosystemRedirector } from "./routing-utils/ecosystem-redirector.route-guard";
import { PlatformRedirector } from "./routing-utils/platform-redirector.route-guard";

export const routes: Route[] = [
    {
        path: "cefi/login",
        pathMatch: "full",
        children: [],
        canActivate: [PlatformRedirector],
    },
    {
        path: "cefi/connect",
        pathMatch: "full",
        children: [],
        canActivate: [EcosystemRedirector],
    },
    {
        path: "cefi",
        loadChildren: () =>
            import("@cloudextend/cefi/ui").then(m => m.CefiUiModule),
    },
];
