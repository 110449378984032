import { CommonModule } from "@angular/common";
import { HttpClientModule } from "@angular/common/http";
import { NgModule } from "@angular/core";
import { MatButtonModule } from "@angular/material/button";
import { MatProgressBarModule } from "@angular/material/progress-bar";
import { RouterModule } from "@angular/router";
import { LottieModule } from "ngx-lottie";

import { CustomLayoutData } from "@cloudextend/common/ui";

import { NoSubscriptionPageComponent } from "./no-subscription-page/no-subscription-page.component";
import { SubscriptionExpiredPageComponent } from "./subscription-expired-page/subscription-expired-page.component";

@NgModule({
    declarations: [],
    exports: [],
    imports: [
        CommonModule,
        LottieModule,
        MatButtonModule,
        MatProgressBarModule,
        HttpClientModule,
        RouterModule.forChild([
            {
                path: "cefi/subscription-expired",
                pathMatch: "full",
                component: SubscriptionExpiredPageComponent,
                data: {
                    layout: {
                        bannerOptions: { hideMenu: false, hideSearchBox: true },
                    },
                } as CustomLayoutData,
            },
            {
                path: "cefi/no-subscription",
                pathMatch: "full",
                component: NoSubscriptionPageComponent,
                data: {
                    layout: {
                        bannerOptions: { hideMenu: false, hideSearchBox: true },
                    },
                } as CustomLayoutData,
            },
        ]),
    ],
})
export class SubscriptionModule {}
