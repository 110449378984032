<div class="message-context-block">
    <div
        fxLayout="column"
        fxLayoutAlign="space-between"
        *ceLet="associations$ | async as associations"
        class="main-body"
    >
        <h2 class="sub-heading">Associated Records</h2>
        <prp-associated-records-list
            *ngIf="wasAssociationsLoaded$ | async; else shimmer"
            [associations]="associations"
        ></prp-associated-records-list>

        <h2 class="sub-heading block-seperator">Available Records</h2>
        <prp-available-records-list
            #availableList
            *ngIf="wasSuggestionsLoaded$ | async; else shimmer"
            [records]="suggestions$ | async"
            [showAttachButton]="hasAttachments$ | async"
            (attachmentButtonClicked)="onAttachmentButtonClick($event)"
            [(selection)]="selectedItems"
            class="records-available"
        >
        </prp-available-records-list>

        <div
            *ceLet="selectedItems?.length || 0 as selectionCount"
            class="footer-actions"
        >
            <mat-divider></mat-divider>
            <!-- TODO: Autopilot status -->
            <prp-autopilot-band *ngIf="!!selectionCount"></prp-autopilot-band>

            <div fxLayoutAlign="center" class="action-button">
                <!-- prettier-ignore -->
                <button
                    mat-raised-button
                    color="primary"
                    [disabled]="!selectionCount"
                    [matBadgeHidden]="!selectionCount"
                    [matBadge]="selectionCount"
                    matBadgeColor="accent"
                    matTooltip="Save to {{selectionCount}} records"
                    matTooltipPosition="above"
                    (click)="onSaveAssociations()"
                    class="attach-button"
                >
                    <mat-icon>save</mat-icon>
                    ASSOCIATE EMAIL
                </button>
            </div>
        </div>
    </div>
</div>

<ng-template #shimmer>
    <cloudextend-list-shimmer
        class="list-shimmer"
        [virtualItemsCount]="3"
    ></cloudextend-list-shimmer>
</ng-template>
