import { Injectable } from "@angular/core";
import { Actions, createEffect } from "@ngrx/effects";
import { Update } from "@ngrx/entity";
import { Store } from "@ngrx/store";
import { catchError, map, switchMap } from "rxjs/operators";

import { NoticeSeverity, notify } from "@cloudextend/common/core";
import { busy, idle } from "@cloudextend/common/core";
import { onEvent } from "@cloudextend/common/events";

import { AssociationsService } from "../../associations/associations.service";
import { MessageContextEvents } from "../../message-context";
import { Association, Candidate, MailItem } from "../../model";
import { LoaderEffect } from "../loader-effects";

import {
    associationsCreated,
    associationsLoaded,
    associationsRequired,
} from "./associations.events";

@Injectable()
export class AssociationsEffects extends LoaderEffect<
    Association,
    { item: MailItem }
> {
    constructor(
        actions$: Actions,
        private readonly associationsSvc: AssociationsService,
        private readonly store: Store
    ) {
        super(actions$, {
            loadingFunction: event =>
                associationsSvc.fetchAssociations(event.item),
            loadedEvent: associationsLoaded,
            triggeringEvent: associationsRequired,
        });
    }

    saveAssociationsOnCreation$ = createEffect(
        () =>
            this.actions$.pipe(
                onEvent(associationsCreated),
                switchMap(event => {
                    const { associations } = event;
                    this.store.dispatch(
                        busy(this.name, {
                            message:
                                "Associating email to the selected records.",
                        })
                    );
                    return this.associationsSvc
                        .saveAssociations(associations)
                        .pipe(
                            map((associatedRecords: Association[]) => {
                                let severity: NoticeSeverity;
                                let message: string;
                                if (associatedRecords.length > 0) {
                                    this.store.dispatch(
                                        MessageContextEvents.associationsUpdated(
                                            this.name,
                                            { value: associatedRecords }
                                        )
                                    );

                                    if (
                                        associatedRecords.length ===
                                        associations.length
                                    ) {
                                        //full success
                                        severity = NoticeSeverity.success;
                                        message = `Email associated to ${
                                            associatedRecords.length
                                        } record(s) succesfully!`;
                                    } else {
                                        //partial success
                                        this.dispatchOnFailure(associatedRecords);
                                        severity = NoticeSeverity.error;
                                        message = `Failed associating email to ${
                                            associations.length -
                                            associatedRecords.length
                                        } record(s)!`;
                                    }
                                } else {
                                    //failure
                                    this.dispatchOnFailure(associations);
                                    severity = NoticeSeverity.error;
                                    message =
                                        "Failed associating email to the selected records!";
                                }

                                this.store.dispatch(
                                    notify(this.name, {
                                        notice: {
                                            severity: severity,
                                            message: message,
                                        },
                                    })
                                );

                                this.store.dispatch(idle(this.name));
                            }),
                            catchError(async () => {
                                this.store.dispatch(
                                    notify(this.name, {
                                        notice: {
                                            severity: NoticeSeverity.error,
                                            message:
                                                "Failed associating email to the selected records!",
                                        },
                                    })
                                );
                                this.dispatchOnFailure(associations);
                                this.store.dispatch(idle(this.name));
                            })
                        );
                })
            ),
        { dispatch: false }
    );

    private dispatchOnFailure(associations) {
        //TODO replace with PRP-481
        associations.forEach(association => {
            const update = {
                id: `${association.record.type}:${association.record.id}`,
                changes: {
                    includeInlineImages: undefined,
                    selectedAttachments: undefined,
                },
            } as Update<Candidate>;
            this.store.dispatch(
                MessageContextEvents.candidateUpdated(this.name, {
                    update,
                })
            );
        });
    }

    protected get name() {
        return "MessageContext/Associations/Effects";
    }
}
