import { Injectable } from "@angular/core";
import { Observable } from "rxjs";

import { ResultsPage } from "./results-page";
import { SearchOptions } from "./search-options";

@Injectable({
    providedIn: "root",
})
export abstract class GlobalSearchService {
    public abstract search(
        query: string,
        options?: SearchOptions
    ): Observable<ResultsPage>;
}
