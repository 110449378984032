import { Component, EventEmitter, Output } from "@angular/core";
import { Store } from "@ngrx/store";

import {
    getBusyMessageSubText,
    getBusyMessaging,
    getIsBusy,
    getIsCancellable,
} from "./busy-state.reducer";

@Component({
    selector: "cloudextend-busy-state",
    templateUrl: "./busy-state.component.html",
    styleUrls: ["./busy-state.component.scss"],
})
export class BusyStateComponent {
    constructor(private readonly store: Store) {}

    public readonly isBusy$ = this.store.select(getIsBusy);
    public readonly message$ = this.store.select(getBusyMessaging);
    public readonly subText$ = this.store.select(getBusyMessageSubText);
    public readonly isCancellable$ = this.store.select(getIsCancellable);

    @Output() cancelled = new EventEmitter();

    onCancel(): void {
        this.cancelled.emit();
    }
}
