import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { EffectsModule } from "@ngrx/effects";
import { StoreModule } from "@ngrx/store";

import { AssociationsEffects } from "./associations/associations.effect";
import { SuggestionsEffects } from "./candidates";
import { MessageContextEffects } from "./message-context.effects";
import { contextChangeMetaReducer, reducers } from "./message-context.reducer";
import { FEATURE_KEY } from "./message-context.shared";

@NgModule({
    declarations: [],
    imports: [
        CommonModule,
        StoreModule.forFeature(FEATURE_KEY, reducers, {
            metaReducers: [contextChangeMetaReducer],
        }),
        EffectsModule.forFeature([
            MessageContextEffects,
            AssociationsEffects,
            SuggestionsEffects,
        ]),
    ],
})
export class MessageContextModule {}
