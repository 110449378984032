import { Injectable, Injector, Type } from "@angular/core";
import { Actions, createEffect } from "@ngrx/effects";
import { Store } from "@ngrx/store";
import { Observable, Subject } from "rxjs";
import { map, takeWhile } from "rxjs/operators";

import { fault, Logger, LogService } from "@cloudextend/common/core";
import { occurenceOf, onEvent } from "@cloudextend/common/events";
import { navigate } from "@cloudextend/common/routes";

import { DefaultWorkflow } from "./default-workflow";
import { Workflow } from "./workflow";
import { skipSteps, nextStep, previousStep, goto } from "./workflow.events";

export interface WorkflowStepEvent {
    stepName: string;
    stepIndex: number;
}

@Injectable({ providedIn: "root" })
export class WorkflowEngine {
    constructor(
        private readonly actions$: Actions,
        private readonly injector: Injector,
        private readonly store: Store,
        logs: LogService
    ) {
        this.logger = logs.createLogger("WorkflowEngine");
        this.defaultWorkflow = injector.get(
            DefaultWorkflow,
            new DefaultWorkflow(store)
        );
    }

    onNextStep$ = createEffect(
        () =>
            this.actions$.pipe(
                onEvent(nextStep),
                map(() => {
                    this.gotoNextStep();
                    this.activateNextStep();
                })
            ),
        { dispatch: false }
    );

    onPreviousStep$ = createEffect(
        () =>
            this.actions$.pipe(
                onEvent(previousStep),
                map(() => {
                    this.gotoPreviousStep();
                    this.activateNextStep();
                })
            ),
        { dispatch: false }
    );

    onSkipSteps$ = createEffect(
        () =>
            this.actions$.pipe(
                onEvent(skipSteps),
                map(event => {
                    this.skipSteps(event.value);
                    this.activateNextStep();
                })
            ),
        { dispatch: false }
    );

    onGoTo$ = createEffect(
        () =>
            this.actions$.pipe(
                onEvent(goto),
                map(event => {
                    if (!this.currentWorkflow) {
                        throw fault(
                            `There is no active workflow. Cannot go to step ${event.value}`
                        );
                    }
                    const stepIndex = this.currentWorkflow.stepIndexByName.get(
                        event.value
                    );
                    if (!stepIndex && stepIndex !== 0) {
                        this.logger.error(
                            `Attempted to go to step unknown step '${event.value}'.`
                        );
                        return;
                    }
                    this.nextStepIndex = stepIndex;
                    this.activateNextStep();
                })
            ),
        { dispatch: false }
    );

    private currentWorkflow: Workflow | undefined;
    private currentWorkflowEvents$: Subject<WorkflowStepEvent> | undefined;

    private nextStepIndex = 0;
    private currentContext: Record<string, unknown> = {};

    private readonly logger: Logger;
    private readonly defaultWorkflow: Workflow;

    public executeWorkflow(
        workflowOrType: Type<Workflow> | Workflow
    ): Observable<WorkflowStepEvent> {
        this.currentWorkflowEvents$ = new Subject<WorkflowStepEvent>();
        this.currentContext = {};

        if (typeof workflowOrType === "function") {
            this.currentWorkflow = this.injector.get(workflowOrType);
            this.nextStepIndex = 0;
        } else {
            this.currentWorkflow = workflowOrType;
            this.nextStepIndex = 0;
        }
        this.logger.debug("Starting workflow %s", this.currentWorkflow.name);
        this.activateNextStep();
        return this.currentWorkflowEvents$;
    }

    private activateNextStep(): void {
        if (!this.currentWorkflow) {
            this.nextStepIndex = 0;
            this.executeOnCompleteAction();
            return;
        } else if (this.nextStepIndex >= this.currentWorkflow.steps.length) {
            this.nextStepIndex = 0;
            this.executeOnCompleteAction();
            this.currentWorkflowEvents$?.complete();
            this.logger.debug("%s was completed.", this.currentWorkflow.name);

            delete this.currentWorkflow;
            return;
        }

        if (this.nextStepIndex < 0) this.nextStepIndex = 0;

        const currentStepIndex = this.nextStepIndex;
        const currentStep = this.currentWorkflow.steps[currentStepIndex];

        this.logger.debug(
            "Executing step %s: %s...",
            currentStepIndex,
            currentStep.name
        );

        let autoforward = true;
        currentStep
            .activate(this.currentContext)
            .pipe(takeWhile(() => currentStepIndex === this.nextStepIndex))
            .subscribe({
                next: event => {
                    if (occurenceOf(nextStep, event)) {
                        this.gotoNextStep();
                    } else if (occurenceOf(previousStep, event)) {
                        this.gotoPreviousStep();
                    } else if (occurenceOf(skipSteps, event)) {
                        this.skipSteps(
                            (event as ReturnType<typeof skipSteps>).value
                        );
                    } else if (occurenceOf(navigate, event)) {
                        this.store.dispatch(event);
                        autoforward = false;
                    } else {
                        this.store.dispatch(event);
                    }

                    this.currentWorkflowEvents$?.next({
                        stepIndex: currentStepIndex,
                        stepName: currentStep.name,
                    });
                },
                error: this.currentWorkflowEvents$?.error,
                complete: () => {
                    if (autoforward) {
                        if (this.nextStepIndex === currentStepIndex) {
                            this.nextStepIndex++;
                        }
                        this.activateNextStep();
                    }
                },
            });
    }

    private executeOnCompleteAction() {
        const onCompletion = this.currentWorkflow
            ? this.currentWorkflow.onCompletion
            : this.defaultWorkflow.onCompletion;

        const completionEvents = onCompletion(this.currentContext);

        if (Array.isArray(completionEvents)) {
            completionEvents.forEach(this.store.dispatch);
        } else {
            this.store.dispatch(completionEvents);
        }
    }

    private skipSteps(skips: number) {
        this.logger.debug("Skip %s steps...", skips);
        this.nextStepIndex += 1 + skips;
        return this.nextStepIndex;
    }

    private gotoPreviousStep() {
        this.logger.debug("Returning to previous step...");
        this.nextStepIndex--;
        return this.nextStepIndex;
    }

    private gotoNextStep() {
        this.logger.debug("Begining next step...");
        this.nextStepIndex++;
        return this.nextStepIndex;
    }
}
