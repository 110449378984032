import { Injectable } from "@angular/core";

import {
    fault,
    isNumber,
    LocalStorageService,
    Logger,
    LogService,
} from "@cloudextend/common/core";

import { SubscriptionInfo, SubscriptionStatus } from "../session";

export const SUBSCRIPTON_STORAGE_KEY = "cefi.session.subscription";

@Injectable({ providedIn: "root" })
export class SubscriptionService {
    constructor(
        private readonly storageSvc: LocalStorageService,
        logSevice: LogService
    ) {
        this.logger = logSevice.createLogger("SubscriptionService");
    }

    private readonly logger: Logger;
    private readonly defaultSub = {status: SubscriptionStatus.Unlicensed} as SubscriptionInfo;

    private isValidSubscription(subscription: SubscriptionInfo): boolean {
        return subscription && isNumber(subscription.expireAtEpochSeconds);
    }

    private parseSubscription(
        subscription: string
    ): SubscriptionInfo | undefined {

        if (!subscription) return this.defaultSub;

        const parsedSub = JSON.parse(subscription) as SubscriptionInfo;
        return this.isValidSubscription(parsedSub) ? parsedSub : this.defaultSub;
    }

    public readFromCache(): SubscriptionInfo {
        try {
            const subJson = this.storageSvc.get(SUBSCRIPTON_STORAGE_KEY);
            if (!subJson) return this.defaultSub;

            const subscription = this.parseSubscription(subJson) || this.defaultSub;

            return subscription;
        } catch (err) {
            this.logger.error("Error loading subscription from cache.", err);
            return this.defaultSub;
        }
    }

    public cacheSubscription(subscription: SubscriptionInfo): void {
        if (this.isValidSubscription(subscription)) {
            const subscriptionJson = JSON.stringify(subscription);
            this.storageSvc.save(SUBSCRIPTON_STORAGE_KEY, subscriptionJson);
        } else {
            this.logger.warn("Specified subscription is invalid", subscription);
            throw fault(
                "There was an error storing the subscription in the cache."
            );
        }
    }

    public removeSubscriptionFromCache(): void {

        try{
            this.storageSvc.removeItem(SUBSCRIPTON_STORAGE_KEY);
        } catch (err) {
            this.logger.error("Error while removing subscription from cache", err);
            throw fault(
                "There was an error removing the subscription in the cache."
            );
        }
    }
}
