<div class="input-wrapper global-search">
    <div #dropreference class="input">
        <mat-form-field appearance="outline">
            <mat-icon class="main-search-box-keep-alive" prefix
                >search</mat-icon
            >
            <input
                class="main-search-box-keep-alive"
                #input
                type="text"
                matInput
                placeholder="{{ placeholder }}"
                autocomplete="off"
                [(ngModel)]="searchText"
                (click)="showDropdown()"
            />
        </mat-form-field>
        <custom-dropdown
            [reference]="dropreference"
            #dropdownComp
            class="main-search-box-keep-alive"
            id="custom-drop-down"
        >
            <div class="mat-elevation-z2 dropdown-options-container">
                <div *ngIf="supportedFilters.length">
                    <ng-container
                        *ngTemplateOutlet="filterSection"
                    ></ng-container
                    >;
                    <hr />
                </div>
                <div *ngIf="!searchText?.length">
                    <ng-container *ngTemplateOutlet="hintsView"></ng-container>
                </div>
                <div *ngIf="!isTextSearchable(searchText)">
                    <ng-container
                        *ngTemplateOutlet="awaitingText"
                    ></ng-container>
                </div>
                <div *ngIf="isTextSearchable(searchText)">
                    <ng-container
                        *ngTemplateOutlet="resultsView"
                    ></ng-container>
                </div>
            </div>
        </custom-dropdown>
    </div>
</div>

<ng-template #filterSection>
    <mat-accordion displayMode="flat">
        <mat-expansion-panel class="mat-elevation-z0" #filterPanel>
            <mat-expansion-panel-header class="main-search-box-keep-alive">
                <mat-panel-title>Filter</mat-panel-title>
                <mat-panel-description>{{
                    filter?.text || supportedFilters[0]?.text
                }}</mat-panel-description>
            </mat-expansion-panel-header>
            <mat-selection-list
                [multiple]="false"
                (selectionChange)="onFilterChanged($event); filterPanel.close()"
            >
                <mat-list-option
                    *ngFor="let filter of supportedFilters"
                    [value]="filter"
                >
                    {{ filter.text }}
                </mat-list-option>
            </mat-selection-list>
        </mat-expansion-panel>
    </mat-accordion>
</ng-template>

<ng-template #hintsView>
    <div class="search-hint">
        <span class="search-suggestions">Suggestions</span>
        <mat-list class="search-suggestions" *ngIf="suggestions">
            <mat-list-item
                *ngFor="let suggestion of suggestions"
                class="search-suggestion-items"
            >
                <span class="search-suggestion-text">
                    {{ suggestion }}
                </span>
            </mat-list-item>
            <!-- Removed until we have the doc
            <mat-list-item>
                <a href="#" target="_blank"> More search tips... </a>
            </mat-list-item>
            -->
        </mat-list>
    </div>
</ng-template>

<ng-template #awaitingText>
    <div class="search-results">
        <span class="search-result-searching">
            Type 3 or more characters...
        </span>
    </div>
</ng-template>

<ng-template #resultsView>
    <div
        class="search-results"
        *ngIf="searchResults$ | async; let results"
        [ngSwitch]="
            results.records.length + (results.pageContext?.pageIndex || 0)
        "
    >
        <div *ngSwitchCase="-1">
            <span class="search-result-searching">Searching...</span>
        </div>
        <div *ngSwitchCase="0">
            <span class="search-result-no-matches"
                >No matching records found</span
            >
        </div>
        <div *ngSwitchDefault>
            <mat-selection-list
                [multiple]="false"
                (selectionChange)="onResultSelected($event)"
            >
                <mat-list-option
                    *ngFor="let record of results.records"
                    [value]="record"
                    #resultsList
                >
                    <span class="search-result-text">
                        {{ record["_title"] || record.name }}
                    </span>
                    <span class="search-result-subtext">
                        {{ record["_subTitle"] || record.type }}
                    </span>
                </mat-list-option>
            </mat-selection-list>
        </div>
    </div>
</ng-template>
