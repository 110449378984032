import { Creator } from "@ngrx/store";

import { EventCreator, ObjectLike, RxEvent } from "@cloudextend/common/events";

import { navigate, NavigationEvent } from "./navigation";
import { extractUrlSegments } from "./url-segments-extractor";

export interface View {
    stateName: string;
}

export type ViewCreator<C extends Creator = Creator> = EventCreator<C> & View;

export function where<T extends ObjectLike>() {
    return ("params" as unknown) as T;
}

export interface UnparamterizedNavigation
    extends View,
        ViewCreator<(source: string) => NavigationEvent> {}

export interface ParameterizedNavigation<T>
    extends View,
        ViewCreator<
            (
                soure: string,
                params: T,
                queryParams?: Record<string, unknown>
            ) => NavigationEvent
        > {}

export function createView(path: string): UnparamterizedNavigation;
export function createView<ParamsType extends Record<string, unknown>>(
    path: string,
    paramsHint: ParamsType
): ParameterizedNavigation<ParamsType>;
export function createView<ParamsType extends Record<string, unknown>>(
    path: string,
    ...params: ParamsType[]
): (
    source: string,
    ...params: ParamsType[]
) => RxEvent | (RxEvent & ParamsType) {
    if (!params || !params.length) {
        const pathSegments = [path];
        const action = (source: string) => navigate(source, { pathSegments });
        viewify(action, path);
        return action;
    } else {
        const pathSegments = extractUrlSegments(path);
        const action = (
            source: string,
            params: ParamsType,
            queryParams?: Record<string, unknown>
        ) => navigate(source, { pathSegments, params, queryParams });
        viewify(action, path);
        return action;
    }
}

function viewify(creator: Creator, stateName: string): void {
    Object.defineProperty(creator, "stateName", {
        value: stateName,
        writable: false,
    });
}
