import { Component, EventEmitter, Input, Output } from "@angular/core";

@Component({
    selector: "cloudextend-content-header",
    templateUrl: "./content-header.component.html",
    styleUrls: ["./content-header.component.scss"],
})
export class ContentHeaderComponent {
    @Output() closed = new EventEmitter<void>();

    @Input() title: string;
    @Input() canClose = true;
}
