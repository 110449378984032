import { args, declareEvent } from "@cloudextend/common/events";

import { ContextType, MailItem } from "../model";

export const contextChanged = declareEvent(
    "Context Changed",
    args<{ contextType: ContextType; item?: MailItem }>()
);

export const enteredNoContext = declareEvent("Entered No-Context");

export const enteredReadMode = declareEvent(
    "Entered Message Read",
    args<{ item: MailItem }>()
);

export const enteredComposeMode = declareEvent(
    "Entered Message Compose",
    args<{ item: MailItem }>()
);

export const nonContextualViewLoaded = declareEvent(
    "Non-Contextual page loaded"
);

export const contextualViewLoaded = declareEvent("Contextual page loaded");
