import { Component, OnInit } from "@angular/core";

@Component({
  selector: "prp-no-bundle-page",
  templateUrl: "./no-bundle-page.component.html",
  styleUrls: ["./no-bundle-page.component.scss"]
})
export class NoBundlePageComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
