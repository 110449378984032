import { createFeatureSelector, createSelector } from "@ngrx/store";

import { createEventReducer, when } from "@cloudextend/common/events";

import { skipWelcomeChanged } from "./preferences.events";

export const featureKey = "preferences";

export interface State {
    skipWelcome: boolean;
}

const initialState = {
    skipWelcome: false,
} as State;

export const reducer = createEventReducer(
    initialState,
    when(skipWelcomeChanged, (state, event) => ({
        ...state,
        skipWelcome: event.newValue,
    }))
);

const getUiPreferences = createFeatureSelector<State>(featureKey);
export const getSkipWelcome = createSelector(
    getUiPreferences,
    state => !!state?.skipWelcome
);
