export function startsWithIgnoreCase(longerText: string, startText: string) {
    return substringEqualsIgnoreCase(longerText, startText, 0);
}

export function substringEquals(
    longerText: string,
    match: string,
    startingFrom: number
) {
    return (
        longerText &&
        match &&
        longerText.length >= startingFrom + match.length &&
        substringEqualsInternal(
            longerText,
            match,
            startingFrom,
            (l, r) => l === r
        )
    );
}

export function substringEqualsIgnoreCase(
    longerText: string,
    match: string,
    startingFrom: number
) {
    return (
        longerText &&
        match &&
        longerText.length >= startingFrom + match.length &&
        // && equalsIgnoreCase(match, longerText.substr(startingFrom, match.length));
        substringEqualsInternal(
            longerText,
            match,
            startingFrom,
            equalsIgnoreCase
        )
    );
}

function substringEqualsInternal(
    longerText: string,
    match: string,
    startingFrom: number,
    comparer: (lhs: string, rhs: string) => boolean
) {
    for (let i = match.length - 1; i >= 0; i--) {
        if (!comparer(match.charAt(i), longerText.charAt(startingFrom + i))) {
            return false;
        }
    }
    return true;
}

export function equalsIgnoreCase(lhs: string, rhs: string): boolean {
    return (
        lhs === rhs ||
        (!!lhs &&
            lhs.localeCompare(rhs, undefined, { sensitivity: "base" }) === 0)
    );
}

export function pathCombine(first: string, second: string): string {
    const hasLeadingSlash = second.charAt(0) === "/";
    const hasTrailingSlash = first.charAt(first.length - 1) === "/";

    let path: string;
    if (hasLeadingSlash && hasTrailingSlash) {
        path = first + second.substring(1);
    } else if (hasLeadingSlash || hasTrailingSlash) {
        path = first + second;
    } else {
        path = first + "/" + second;
    }
    return path;
}

export function pluralizeByNumber(
    count: number,
    singular: string,
    plural?: string
): string {
    return count === 1 ? singular : plural ? plural : singular + "s";
}

export function safeStringConvertion(def: unknown): string {
    if (typeof def === "string") {
        return def;
    } else if (
        typeof def === "number" ||
        typeof def === "boolean" ||
        typeof def === "bigint"
    ) {
        return "" + def;
    } else if (typeof def === "undefined") {
        return "(undefined)";
    } else if (def && typeof def === "object" && !Object.keys(def).length) {
        return "" + def;
    }

    try {
        return JSON.stringify(def, undefined, 4);
    } catch {
        if (def && typeof def === "object") {
            return Object.entries(def)
                .map(x => x.join(":"))
                .join("\r\n");
        } else {
            return "" + def;
        }
    }
}
