"use strict";
import { Injectable } from "@angular/core";

import { Logger, LogService } from "@cloudextend/common/core";

@Injectable({ providedIn: "root" })
export class InboxsdkListenerService {
    private readonly logger: Logger;

    constructor(logService: LogService) {
        this.logger = logService.createLogger("InboxsdkListnerService");
    }

    public registerEventHandler(event, handler) {
        this.logger.debug("Registering event handler to event: %s", event);
        window.addEventListener(event, handler, false);
    }

    public destroyEventHandler(event, handler) {
        this.logger.debug("Destroying event handler to event: %s", event);
        window.removeEventListener(event, handler, false);
    }
}
