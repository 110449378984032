import { Injectable } from "@angular/core";

import { EcosystemEnvironment } from "../ecosystem";
import { Logger, LogService } from "../log.service";

import { AccountStorage } from "./account-storage";
import { BestEffortPersistentStorage } from "./best-effort-storage";
import { ClientStorage } from "./client-storage";
import { SharedClientStorage } from "./shared-storage";

@Injectable({
    providedIn: "root",
})
export class StorageService {
    private _logger: Logger;

    private _basekey = "";

    constructor(logService: LogService) {
        this._logger = logService.createLogger("StorageService");
    }

    public qualifyKey = (key: string): string => {
        return this._basekey + key;
    };

    public setAccount = (
        system: string,
        account: string,
        accountType?: EcosystemEnvironment
    ) => {
        if (system && system.length > 0) this._basekey = system + "_";
        if (account && account.length > 0) {
            this._basekey = this._basekey + account + "_";
        }
        if (accountType === 0 || accountType) {
            this._basekey =
                this._basekey + EcosystemEnvironment[accountType] + "_";
        }
    };

    public getSharedStorage = (): ClientStorage =>
        new SharedClientStorage(this);

    public getAccountStorage = (): ClientStorage => new AccountStorage(this);

    public getUnderlyingStorage = (): Storage => {
        if (localStorage) return new BestEffortPersistentStorage();

        this._logger.warn(
            "LocalStorage is unavailable. Using SessionStorage..."
        );
        return sessionStorage;
    };
}
