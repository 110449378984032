import { Injectable } from "@angular/core";
import { Store } from "@ngrx/store";
import { filter } from "rxjs/operators";

import { events } from "@cloudextend/cefi/state";
import {
    busy,
    Environment,
    EnvironmentService,
    fault,
    Logger,
    LogService,
    MessageRelayService,
    NoticeSeverity,
    NotificationService,
    takeOnce,
    WindowMessage,
} from "@cloudextend/common/core";
import { WorkflowEngine } from "@cloudextend/common/workflows";

const LOGOUT_PAGE = "CEGA/GoogleLogoutPage";

@Injectable({ providedIn: "root" })
export class LogoutService {
    constructor(
        envService: EnvironmentService,
        private readonly messageRelay: MessageRelayService,
        private readonly notificationService: NotificationService,
        private readonly store: Store,
        private readonly workflowEngine: WorkflowEngine,
        logSevice: LogService
    ) {
        this.environment = envService.getEnvironment();
        this.logger = logSevice.createLogger("LogOutService");
    }

    private readonly environment: Environment;
    private readonly logger: Logger;

    public signOut() {
        this.store.dispatch(
            busy(LOGOUT_PAGE, { message: "Signing out from the session..." })
        );

        const params = `width=600px,height=400px,left=100,top=200`;

        //We don't have to show an error in case b2c logout fails,
        //as we are clearing the session anyway.

        this.store.dispatch(events.loggedOut(LOGOUT_PAGE));

        const url = `${this.environment.apiBaseUrl}/cefi/auth/v1/signout`;
        const popup = window.open(url, "popup", params);
        if (!popup) {
            throw fault(
                "There was an error opening the signout popup window.",
                { isUserReadable: true }
            );
        }

        this.messageRelay.messages$
            .pipe(
                filter(message => message.messageType === "signOut"),
                takeOnce()
            )
            .subscribe(message => this.onLogoutMessage(message));
    }

    private onLogoutMessage(message: WindowMessage) {
        if (message.isSuccess) {
            this.logger.debug("Logout was successful");
            this.notificationService.send({
                message: "We have successfully signed you out",
                severity: NoticeSeverity.info,
            });
        } else {
            this.logger.error("Failed to logout from backend", message.error);
        }

        window.location.reload();
        return;
    }
}
