export interface Connection {
    connectionId: string;
    account: string;
    roleId?: string;
    encryptedConnection: string;
}

export function isValidConnection(rawData: unknown): rawData is Connection {
    if (typeof rawData === "object") {
        const assumed = rawData as Connection;
        return !!(assumed.connectionId && assumed.account);
    } else {
        return false;
    }
}
