import { CommonModule } from "@angular/common";
import { HttpClientModule } from "@angular/common/http";
import { NgModule } from "@angular/core";

import { GlobalSearchService } from "@cloudextend/ecosystems/common";

import { RestletSearchService } from "./restlet-search.service";

@NgModule({
    imports: [CommonModule, HttpClientModule],
    providers: [
        { provide: GlobalSearchService, useClass: RestletSearchService },
    ],
})
export class RestletSearchModule {}
