import { Injectable } from "@angular/core";
import { createEffect, Actions } from "@ngrx/effects";
import { Store } from "@ngrx/store";
import { concatMap, map, take } from "rxjs/operators";

import { StorageService } from "@cloudextend/common/core";
import { onEvent } from "@cloudextend/common/events";

import * as PreferencesEvents from "./preferences.events";
import { skipWelcomeChanged } from "./preferences.events";
import { getSkipWelcome } from "./preferences.reducer";

export const SKIP_WELCOME_STORAGEKEY = "prp.ui.preferences.skipWelcome";
export const PREF_EFFECTS = "Effects/Preferences";

@Injectable()
export class PreferencesEffects {
    constructor(
        private readonly actions$: Actions,
        private readonly storage: StorageService,
        private readonly store: Store
    ) {}

    loadSkipWelcome$ = createEffect(() =>
        this.actions$.pipe(
            onEvent(PreferencesEvents.moduleLoaded),
            map(() => {
                // prettier-ignore
                const newValue = "true" === this.storage
                    .getSharedStorage()
                    .get(SKIP_WELCOME_STORAGEKEY);
                return skipWelcomeChanged(PREF_EFFECTS, { newValue });
            }),
            take(1)
        )
    );

    saveSkipWelcome$ = createEffect(() =>
        this.actions$.pipe(
            onEvent(PreferencesEvents.skipWelcomeToggled),
            concatMap(() =>
                this.store.select(getSkipWelcome).pipe(
                    take(1),
                    map(value => {
                        const newValue = !value;
                        this.storage
                            .getSharedStorage()
                            .save(SKIP_WELCOME_STORAGEKEY, newValue.toString());
                        return skipWelcomeChanged(PREF_EFFECTS, { newValue });
                    })
                )
            )
        )
    );
}
