<h1 class="welcome">
    Welcome <br />
    {{ userDisplayName$ | async }}!
</h1>
<div class="home_img">
    <img src="/assets/home_main.svg" class="home_main" />
</div>
<cloudextend-callout-button
    title="Suggest related records"
    description="Find matching NetSuite records based on sender/recipient email IDs"
    (clicked)="showSuggestedRecords()"
></cloudextend-callout-button>
<mat-checkbox
    [checked]="skipWelcome$ | async"
    (change)="toggleSkipWelcome()"
    class="home-check"
>
    Always show suggested records
</mat-checkbox>
