import { Injectable } from "@angular/core";
import { Store } from "@ngrx/store";

import { Initializer, Logger, LogService } from "@cloudextend/common/core";
import { RxEvent } from "@cloudextend/common/events";
import {
    MessageContextEvents as ContextEvents,
    MailboxService,
    ContextType,
} from "@cloudextend/prp/core";

import { GmailMailItem, GmailEventType } from "./../model";
import { InboxsdkListenerService } from "./inboxsdk-listener.service";

const MAILBOX_SERVICE = "GmailMailboxService";
const EVENT_TYPE = "message";
@Injectable({
    providedIn: "root",
})
export class GmailMailboxService extends MailboxService implements Initializer {
    private readonly logger: Logger;

    constructor(
        private inboxsdkListnerService: InboxsdkListenerService,
        private readonly store: Store,
        logService: LogService
    ) {
        super();
        this.logger = logService.createLogger(MAILBOX_SERVICE);
    }

    public initialize(): void {
        this.inboxsdkListnerService.registerEventHandler(
            EVENT_TYPE,
            this.itemChangeEventHandler
        );
        window.parent.postMessage("SdkEventListnerReady", "*");
    }

    public destroyEventHandler = () => {
        this.inboxsdkListnerService.destroyEventHandler(
            EVENT_TYPE,
            this.itemChangeEventHandler
        );
    };

    private itemChangeEventHandler = event => {
        if (
            event.origin !== "https://mail.google.com" ||
            event.data.type === "webpackOk" ||
            event.data.type === "ACTION"
        ) {
            return;
        }
        this.logger.debug("Received SDK Event: ", event);

        const { type, ...item } = JSON.parse(event.data) as GmailMailItem;

        let contextEvent: RxEvent;

        switch (type) {
            case GmailEventType.messageRead:
                // prettier-ignore
                contextEvent = ContextEvents.contextChanged(
                    MAILBOX_SERVICE, 
                    { item, contextType: ContextType.messageRead }
                );
                break;
            case GmailEventType.recipientsUpdate:
            case GmailEventType.composeRestore:
                // prettier-ignore
                contextEvent = ContextEvents.contextChanged(
                    MAILBOX_SERVICE,
                    { item, contextType: ContextType.messageCompose }
                );
                break;
            case GmailEventType.composeViewUnfocused:
            case GmailEventType.messageSent:
                //TODO attach/save email
                console.log("Attach/save email");
                return; // for now
            case GmailEventType.draftDestroy: // TODO handle diffferently
            default:
                // prettier-ignore
                contextEvent = ContextEvents.contextChanged(
                    MAILBOX_SERVICE, 
                    { contextType: ContextType.none }
                );
                break;
        }
        this.store.dispatch(contextEvent);
    };
}
