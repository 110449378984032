<div fxLayout="row" *ceLet="records?.length || 0 as recCount">
    <mat-checkbox
        class="check-all"
        *ngIf="recCount"
        [checked]="selection?.length === recCount"
        (change)="onSelectAllChanged($event.checked)"
        >All
    </mat-checkbox>
</div>
<mat-selection-list
    #recordsList
    *ngIf="records?.length; else noRecords"
    [compareWith]="recordsComparer"
    [(ngModel)]="selection"
    (ngModelChange)="onSelectionChanged($event)"
>
    <mat-divider></mat-divider>
    <mat-list-option
        *ngFor="let record of records"
        [value]="record"
        #listOption
    >
        <div
            class="user-icon-available"
            [ngClass]="getListItemCssClass(record)"
            matListAvatar
        ></div>
        <div mat-line class="name">{{ record.name }}</div>
        <div mat-line class="position">{{ record.type }}</div>
        <button
            class="attach-files"
            mat-icon-button
            *ngIf="
                showAttachButton &&
                (listOption.selected || !!record.selectedAttachments)
            "
            [matBadge]="record.selectedAttachments?.length"
            [matBadgeHidden]="!record.selectedAttachments?.length"
            (click)="onAttachmentButtonClicked($event, record)"
        >
            <mat-icon class="attachFiles">attach_file</mat-icon>
        </button>
        <mat-divider></mat-divider>
    </mat-list-option>
</mat-selection-list>

<ng-template #noRecords>
    <p class="no-suggest-record"> 
        There are no suggestions. Use the Search box above, to find additional
        records.
    </p>
</ng-template>
