import { Component, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { Store } from "@ngrx/store";

import { views as cefiViews } from "@cloudextend/cefi/core";
import { InitializationWorkflow } from "@cloudextend/cefi/routes";
import { events } from "@cloudextend/cefi/state";
import { LogoutService } from "@cloudextend/cefi/ui";
import { Logger, LogService, RecordRef } from "@cloudextend/common/core";
import {
    AppLayoutComponent,
    BaseAppComponent,
    HistoryService,
} from "@cloudextend/common/ui";
import { WorkflowEngine } from "@cloudextend/common/workflows";
import { MailItem, searchSuggestionAdded } from "@cloudextend/prp/core";

import { GmailMailboxService } from "./core/gmail-mailbox.service";

@Component({
    selector: "cega-root",
    templateUrl: "./app.component.html",
    styleUrls: ["./app.component.scss"],
})
export class AppComponent
    extends BaseAppComponent
    implements OnInit, OnDestroy {
    constructor(
        private readonly gmailMailboxService: GmailMailboxService,
        private readonly logoutService: LogoutService,
        private readonly store: Store,
        private readonly workflowEngine: WorkflowEngine,
        logService: LogService,
        historyService: HistoryService
    ) {
        super(historyService);
        this.logger = logService.createLogger("AppComponent");
    }

    @ViewChild("appLayout") appLayout: AppLayoutComponent;

    public readonly tag = "for NetSuite";
    public readonly title = "CloudExtend " + this.tag;
    public currentMail: MailItem;
    private readonly logger: Logger;

    ngOnInit() {
        this.workflowEngine.executeWorkflow(InitializationWorkflow);
    }

    ngOnDestroy() {
        this.gmailMailboxService.destroyEventHandler();
    }

    public onRecordSelected(record: RecordRef) {
        this.store.dispatch(
            searchSuggestionAdded("@SearchBox", { suggestion: record })
        );
    }

    public manageConnections() {
        this.store.dispatch(cefiViews.listConnections("AppComponent"));
        this.appLayout.toggleMenu();
    }

    public signOut() {
        this.logoutService.signOut();
        this.appLayout.toggleMenu();
    }
}
