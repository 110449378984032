import { Injectable } from "@angular/core";
import { Actions } from "@ngrx/effects";

import { RecordRef } from "@cloudextend/common/core";

import { MailItem } from "../../model";
import { RecordsSuggestionsService } from "../../search";
import { LoaderEffect } from "../loader-effects";

import { suggestionsLoaded, suggestionsRequired } from "./suggestions.events";

@Injectable()
export class SuggestionsEffects extends LoaderEffect<
    RecordRef,
    { item: MailItem }
> {
    constructor(
        actions$: Actions,
        suggestionsService: RecordsSuggestionsService
    ) {
        super(actions$, {
            loadingFunction: event =>
                suggestionsService.fetchSuggestions(event.item),
            loadedEvent: suggestionsLoaded,
            triggeringEvent: suggestionsRequired,
        });
    }

    protected get name() {
        return "MessageContext/Suggestions/Effects";
    }
}
