import { Update } from "@ngrx/entity";

import { args, declareEvent } from "@cloudextend/common/events";

import { Candidate } from "../../model";

export const candidateUpdated = declareEvent(
    "Candidate Updated",
    args<{ update: Update<Candidate> }>()
);
