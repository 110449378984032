import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { MatProgressBarModule } from "@angular/material/progress-bar";
import { StoreModule } from "@ngrx/store";

import { BusyStateComponent } from "./busy-state.component";
import { BUSY_STATE_FEATURE, reducer } from "./busy-state.reducer";

@NgModule({
    declarations: [BusyStateComponent],
    imports: [
        CommonModule,
        MatProgressBarModule,
        StoreModule.forFeature(BUSY_STATE_FEATURE, reducer),
    ],
    exports: [BusyStateComponent],
})
export class BusyStateModule {}
