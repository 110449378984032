import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";

import { MailboxService } from "@cloudextend/prp/core";

import { GmailMailboxService } from "./gmail-mailbox.service";

@NgModule({
    declarations: [],
    imports: [CommonModule],
    providers: [{ provide: MailboxService, useClass: GmailMailboxService }],
})
export class GmailCoreModule {}
