import { Injectable } from "@angular/core";

import { Environment } from "./environment";

declare const jest: unknown;

@Injectable({
    providedIn: "root",
})
export class EnvironmentService {
    private environment: Environment;

    constructor() {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        this.environment = (window as any)?.cloudextend?.environment || {};
    }

    public get isUnitTesting() {
        return typeof jest !== undefined;
    }

    public getEnvironment<T extends Environment>() {
        return this.environment as T;
    }
}
