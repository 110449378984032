import { Connection } from "@cloudextend/cefi/core";
import { args, declareEvent } from "@cloudextend/common/events";

export const connectionRequired = declareEvent("CEFI:Connection Required");

/**
 * Raised when a connection is loaded from local storage and is ready
 * to be used.
 */
export const connectionLoaded = declareEvent(
    "CEFI:State:Connection Loaded",
    args<{ connection: Connection }>()
);

export const connectionUpdated = declareEvent(
    "CEFI:State:Connection Updated",
    args<{ connection: Connection }>()
);

/**
 * Raised when we don't have a connection to use. It maybe that the user
 * has multple connections configured. But, none of them are set as the default.
 */
export const connectionNotFound = declareEvent(
    "CEFI:State:Connection Not Found"
);
