import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { MatProgressBarModule } from "@angular/material/progress-bar";
import { RouterModule } from "@angular/router";

import { ConnectionsListPageComponent } from "./connections-list-page/connections-list-page.component";
import { routes } from "./routes";
import { TokenRefreshPageComponent } from "./token-refresh-page/token-refresh-page.component";

@NgModule({
    declarations: [ConnectionsListPageComponent, TokenRefreshPageComponent],
    imports: [
        CommonModule,
        MatProgressBarModule,
        RouterModule.forChild(routes),
    ],
})
export class CefiUiModule {}
