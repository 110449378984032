import { CommonModule } from "@angular/common";
import { APP_BOOTSTRAP_LISTENER, NgModule } from "@angular/core";

import { initializerFactory } from "../bootstrapping/initializer";

import { MessageRelayService } from "./message-relay.service";

@NgModule({
    declarations: [],
    imports: [CommonModule],
    providers: [
        {
            provide: APP_BOOTSTRAP_LISTENER,
            useFactory: initializerFactory,
            deps: [MessageRelayService],
            multi: true,
        },
    ],
})
export class MessagingModule {}
