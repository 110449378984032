import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { MatButtonModule } from "@angular/material/button";
import { MatIconModule } from "@angular/material/icon";
import { MatProgressBarModule } from "@angular/material/progress-bar";
import { MatSidenavModule } from "@angular/material/sidenav";
import { MatToolbarModule } from "@angular/material/toolbar";
import { RouterModule } from "@angular/router";

import { CalloutButtonComponent } from "./callout-button/callout-button.component";
import { ContentHeaderComponent } from "./content-header/content-header.component";
import { FlyoutToolbarComponent } from "./flyout-toolbar/flyout-toolbar.component";
import { FlyoutComponent } from "./flyout/flyout.component";
import { CommonLayoutModule } from "./layout/common-layout.module";

@NgModule({
    imports: [
        CommonModule,
        CommonLayoutModule,
        MatButtonModule,
        MatIconModule,
        MatProgressBarModule,
        MatSidenavModule,
        MatToolbarModule,
        RouterModule,
    ],
    declarations: [
        ContentHeaderComponent,
        FlyoutComponent,
        FlyoutToolbarComponent,
        CalloutButtonComponent,
    ],
    exports: [CalloutButtonComponent, FlyoutComponent],
})
export class UiModule {}
