import { Injectable } from "@angular/core";
import {
    ActivatedRouteSnapshot,
    CanActivate,
    Router,
    UrlTree,
} from "@angular/router";
import { createSelector, Store } from "@ngrx/store";
import { Observable, of } from "rxjs";
import { map, switchMap } from "rxjs/operators";

import { takeOnce } from "@cloudextend/common/core";
import { getRouteData } from "@cloudextend/common/routes";

import { SkippablePageConfig } from "./skippable-page-config";

const getSkipConfig = createSelector(
    getRouteData,
    (data: { skippable: SkippablePageConfig }) => data.skippable
);

@Injectable({
    providedIn: "root",
})
export class SkippablePageRouteGuardService implements CanActivate {
    constructor(
        private readonly store: Store,
        private readonly router: Router
    ) {}

    private readonly routeData$ = this.store.select(getRouteData);

    canActivate(route: ActivatedRouteSnapshot): Observable<UrlTree | boolean> {
        return this.store.select(getSkipConfig).pipe(
            takeOnce(),
            switchMap(skipConfig => {
                if (!skipConfig) return of(true);

                const { canSkip, skipTo } = skipConfig;
                return this.store.select(canSkip).pipe(
                    takeOnce(),
                    map(shouldSkip => {
                        if (!shouldSkip) return true;

                        const isRelative =
                            shouldSkip || !skipTo.startsWith("/");
                        const path = isRelative
                            ? [...route.url.map(u => u.path), skipTo]
                            : [skipTo];
                        return this.router.createUrlTree(path);
                    })
                );
            })
        );
    }
}
