import { Injectable } from "@angular/core";
import {
    ActivatedRouteSnapshot,
    CanActivate,
    RouterStateSnapshot,
    UrlTree,
} from "@angular/router";
import { Router } from "@angular/router";
import { Store } from "@ngrx/store";
import { Observable } from "rxjs";
import { map, take } from "rxjs/operators";

import {
    Logger,
    LogService,
    substringEqualsIgnoreCase,
} from "@cloudextend/common/core";
import { messageContext } from "@cloudextend/prp/core";

@Injectable({ providedIn: "root" })
export class UnintededRoutingGuard implements CanActivate {
    constructor(
        private readonly router: Router,
        private readonly store: Store,
        logService: LogService
    ) {
        this.logger = logService.createLogger("UnintededRoutingGuard");
    }

    private readonly hasContext$ = this.store.select(
        messageContext.getHasActiveContext
    );

    private readonly currentMailItem$ = this.store.select(
        messageContext.getCurrentMailItemId
    );

    private readonly logger: Logger;

    canActivate(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Observable<boolean | UrlTree> {
        return this.currentMailItem$.pipe(
            take(1),
            map(mailItemId => {
                const hasContext = !!mailItemId;
                const isTowardsNoContext =
                    state.url === "/" ||
                    state.url === "" ||
                    substringEqualsIgnoreCase(state.url, "/no-context", 0);
                if (isTowardsNoContext && hasContext) {
                    this.logger.warn("Blocked!");
                    return false;
                } else if (
                    hasContext &&
                    route.paramMap.get("itemId") !== mailItemId
                ) {
                    return this.router.createUrlTree([
                        "message/read",
                        mailItemId,
                    ]);
                } else {
                    return true;
                }
            })
        );
    }
}
