import { RecordRef } from "@cloudextend/common/core";
import { args, declareEvent } from "@cloudextend/common/events";

import { Candidate, CandidateSource, MailItem } from "../../model";

export const suggestionsRequired = declareEvent(
    "massage-context:suggestions:Required",
    args<{ item: MailItem }>()
);

export const suggestionsLoaded = declareEvent(
    "massage-context:suggestions:Loaded",
    args<{ values: Candidate[] }>()
);

export const suggestionAdded = declareEvent(
    "prp:core:MessageContext:Candidates:Added",
    args<{ record: RecordRef; suggestedReason: CandidateSource }>()
);
