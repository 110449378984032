/* eslint-disable */
import { Injectable } from "@angular/core";
import {
    MatSnackBar,
    MatSnackBarConfig,
    MatSnackBarRef,
    SimpleSnackBar,
} from "@angular/material/snack-bar";

import { Notice, NoticeSeverity } from "./notice";

@Injectable({
    providedIn: "root",
})
export class NotificationService {
    constructor(private _snackBar: MatSnackBar) {}

    send = (notice: Notice): void => {
        const isSticky =
            notice.severity === NoticeSeverity.error ||
            notice.severity === NoticeSeverity.warn;
        const action = isSticky ? "Dismiss" : undefined;
        const duration = isSticky ? undefined : 3000;

        this._snackBar.open(notice.message, action, {
            duration,
            data: notice,
            panelClass: this.getPanelClass(notice),
            verticalPosition: "top",
        });
    };

    openCustomNotification = (
        notice: Notice,
        action?: string,
        config?: MatSnackBarConfig
    ): MatSnackBarRef<SimpleSnackBar> => {
        const actualConfig = config || {
            verticalPosition: "bottom",
            duration: 3000,
            panelClass: this.getPanelClass(notice),
        };
        return this._snackBar.open(notice.message, action, config);
    };

    private getPanelClass = (notice: Notice): string => {
        switch (notice.severity) {
            case NoticeSeverity.error:
                return "error-notification";
            case NoticeSeverity.warn:
                return "warn-notification";
            case NoticeSeverity.success:
                return "success-notification";
            default:
                return "info-notification";
        }
    };
}
