import { args, declareEvent } from "@cloudextend/common/events";

export const moduleLoaded = declareEvent("Module loaded.");

export const skipWelcomeChanged = declareEvent(
    "'Skip Welcome' Changed",
    args<{ newValue: boolean }>()
);

export const skipWelcomeToggled = declareEvent("'Skip Welcome' Toggled");
