import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { EffectsModule } from "@ngrx/effects";

import { ViewStateEffects } from "./view-state";

@NgModule({
    declarations: [],
    imports: [CommonModule, EffectsModule.forFeature([ViewStateEffects])],
})
export class ViewStatesModule {}
