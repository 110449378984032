export class MemoryStorage implements Storage {
    private readonly store = new Map<string, string>();

    [k: string]: unknown;

    get length(): number {
        return this.store.size;
    }

    clear = () => this.store.clear();

    getItem = (key: string): string | null => this.store.get(key) ?? null;

    setItem = (key: string, data: string) => this.store.set(key, data);

    key = (index: number): string | null =>
        Array.from(this.store.keys())[index] ?? null;

    removeItem = (key: string) => this.store.delete(key);
}
