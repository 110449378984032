<cloudextend-busy-state></cloudextend-busy-state>
<cloudextend-app-layout #appLayout [productTag]="tag">
    <cloudextend-main-search-box
        ce-role="searchbox"
        class="global-search-bar"
        (recordSelected)="onRecordSelected($event)"
    ></cloudextend-main-search-box>

    <mat-nav-list ce-role="mainMenu">
        <mat-list-item class="left-menu-action" (click)="manageConnections()">
            <mat-icon mat-list-icon>manage_accounts</mat-icon>
            <span mat-line class="left-menu-action-title">Connections</span>
        </mat-list-item>
        <mat-list-item class="left-menu-action" (click)="signOut()">
            <mat-icon mat-list-icon>logout</mat-icon>
            <span mat-line class="left-menu-action-title">Sign Out</span>
        </mat-list-item>
    </mat-nav-list>
</cloudextend-app-layout>
