export interface BannerOptions {
    buttonStyle?: ButtonStyle;
    hideSearchBox?: boolean;
    title?: string;
    titleStyle?: ButtonStyle;
}

export enum ButtonStyle {
    menu = undefined,
    hidden = 1,
    back = 2,
}

export interface CustomLayout extends Record<string, unknown> {
    bannerOptions?: BannerOptions;
}

export interface CustomLayoutData {
    layout: CustomLayout;
}
