import { Actions, createEffect } from "@ngrx/effects";
import { Observable, of } from "rxjs";
import { catchError, map, switchMap } from "rxjs/operators";

import {
    RxEvent,
    onEvent,
    ParameterizedEventCreator,
    EventCreator,
} from "@cloudextend/common/events";

export interface LoaderConfig<DataType, ParamType> {
    loadingFunction: (event: RxEvent & ParamType) => Observable<DataType[]>;
    triggeringEvent: EventCreator;
    loadedEvent: ParameterizedEventCreator<{ values: DataType[] }>;
}

export abstract class LoaderEffect<DataType, ParamType> {
    constructor(
        protected readonly actions$: Actions,
        private readonly config: LoaderConfig<DataType, ParamType>
    ) {}

    protected abstract get name(): string;

    loadValues$ = createEffect(() =>
        this.actions$.pipe(
            onEvent(this.config.triggeringEvent),
            switchMap(event => this.config.loadingFunction(event)),
            map(values => this.config.loadedEvent(this.name, { values })),
            catchError(() =>
                of(this.config.loadedEvent(this.name, { values: [] }))
            )
        )
    );
}
