<mat-sidenav
    #sideNav
    class="flyout-sideNav"
    position="end"
    mode="over"
    disableClose="false"
>
    <cloudextend-content-header
        #header
        (closed)="close()"
    ></cloudextend-content-header>
    <ng-content></ng-content>
</mat-sidenav>
