import { Inject, Injectable } from "@angular/core";
import { createSelector, Store } from "@ngrx/store";
import { Observable, of } from "rxjs";
import { flatMap, map } from "rxjs/operators";

import {
    Connection,
    ConnectionsService,
    ID_TOKEN_REFRESH_BUFFER_SECONDS,
    isValidConnection,
    Session,
    SessionService,
    SubscriptionInfo,
    SubscriptionService,
    SubscriptionStatus,
    views as cefi,
} from "@cloudextend/cefi/core";
import {
    events,
    events as state,
    getSession,
    hasActiveConnection,
} from "@cloudextend/cefi/state";
import {
    Ecosystem,
    ECOSYSTEM,
    LogRocketService,
    takeOnce,
} from "@cloudextend/common/core";
import { bindSource, RxEvent } from "@cloudextend/common/events";
import {
    events as wf,
    Workflow,
    WorkflowContext,
} from "@cloudextend/common/workflows";

const [nextStep, previousStep, skipSteps] = bindSource(
    "cefi/workflow/Init",
    wf.nextStep,
    wf.previousStep,
    wf.skipSteps
);

const [
    userUpdated,
    sessionLifetimeUpdated,
    connectionLoaded,
    connectionUpdated,
    sessionStarted,
    showLogin,
    showRefreshToken,
    showConnect,
    cefiInitialized,
] = bindSource(
    "cefi/workflow/Init",
    state.userUpdated,
    state.sessionLifetimeUpdated,
    state.connectionLoaded,
    state.connectionUpdated,
    state.sessionStarted,
    cefi.login,
    cefi.refreshToken,
    cefi.connectEcosystem,
    events.cefiInitialized
);

const [showNoSubscription, showSubscriptionExpired] = bindSource(
    "cefi/workflow/Init",
    cefi.noSubscription,
    cefi.subscriptionExpired
);

interface InitWorkflowContext extends WorkflowContext {
    connection?: Connection;
    session?: Session;
    subscription?: SubscriptionInfo;
}

@Injectable()
export class InitializationWorkflow extends Workflow<InitWorkflowContext> {
    constructor(
        private readonly connectionSvc: ConnectionsService,
        private readonly logRocketSvc: LogRocketService,
        private readonly sessionSvc: SessionService,
        private readonly subscriptinSvc: SubscriptionService,
        @Inject(ECOSYSTEM) private readonly ecosystem: Ecosystem,
        store: Store
    ) {
        super("cefi/routes/Init Workflow", store);
    }

    public get onCompletion() {
        return () => cefiInitialized();
    }

    public readonly steps = [
        this.do("Load Session", () => {
            this.logRocketSvc.init();
            const session = this.sessionSvc.readFromCache();
            if (session?.identity && session?.lifetime) {
                return [
                    userUpdated({ identity: session.identity }),
                    sessionLifetimeUpdated({ lifetime: session.lifetime }),
                ];
            } else if (session?.identity) {
                return userUpdated({ identity: session.identity });
            } else {
                return nextStep();
            }
        }),

        this.select("Next step based on Session", () =>
            createSelector(getSession, session => {
                if (!session || !session.identity || !session.lifetime) {
                    return showLogin();
                }

                const nowInSeconds = Date.now() / 1000;
                const BUFFER = ID_TOKEN_REFRESH_BUFFER_SECONDS;

                const {
                    refreshTokenExpiresInEpochSeconds,
                    idTokenExpiresInEpochSeconds,
                } = session.lifetime;

                const refreshTokenExpiring =
                    refreshTokenExpiresInEpochSeconds - nowInSeconds <= BUFFER;
                // Technically, we can still refresh the session at this point. But, we are choosing
                // not to as this means that no token refresh had happened for almost 14 days.

                const sessionRequiresRefresh =
                    !refreshTokenExpiring &&
                    idTokenExpiresInEpochSeconds - nowInSeconds <= BUFFER;

                if (refreshTokenExpiring) {
                    return showLogin();
                } else if (sessionRequiresRefresh) {
                    return showRefreshToken();
                } else {
                    return [sessionStarted({ session }), skipSteps(1)];
                }
            })
        ),

        this.do("Recheck Session", previousStep),

        this.do("Check Subscription", context => {
            const subscription = this.subscriptinSvc.readFromCache();
            context.subscription = subscription;

            const nowInSeconds = Date.now() / 1000;

            if (
                subscription && subscription.expireAtEpochSeconds &&
                subscription.expireAtEpochSeconds > nowInSeconds &&
                subscription.status == SubscriptionStatus.Active
            ) {
                return nextStep();
            } else if (
                subscription.expireAtEpochSeconds < nowInSeconds ||
                subscription.status == SubscriptionStatus.Expired
            ) {
                return showSubscriptionExpired();
            } else {
                return showNoSubscription();
            }
        }),

        this.do("Load active connection", context => {
            const connection = this.connectionSvc.getActiveConnection(
                this.ecosystem
            );
            // We need to store the connection in the Context because,
            // even if we dispatch connectionLoaded event below, due to
            // store.dispatch being async, there's no guarantee that
            // the store will be udpated with connection by the time we get
            // to the next (skip) step.
            context.connection = connection;

            return isValidConnection(connection)
                ? [connectionLoaded({ connection }), skipSteps(1)]
                : nextStep();
        }),

        this.await(
            "Fetch connection",
            `Connecting you to ${this.ecosystem.label}...`,
            context => {
                let connection: Connection;
                return this.connectionSvc
                    .getAvailableConnections(this.ecosystem)
                    .pipe(
                        flatMap((connections: Connection[]) => {
                            if (connections.length) {
                                if (
                                    connections.length === 1 &&
                                    isValidConnection(connections[0])
                                ) {
                                    connection = connections[0];
                                } else {
                                    // TODO: list connections if more than one
                                    connection =
                                        connections[connections.length - 1];
                                }

                                context.connection = connection;
                                return this.populateConnection(
                                    this.ecosystem,
                                    connection
                                );
                            }
                            return of(showConnect());
                        })
                    );
            }
        ),

        this.select("Recheck connection", context =>
            // Connection could have been loaded by this workflow or it could have been
            // loaded elsewhere (e.g. in showConnect() view)
            createSelector(hasActiveConnection, hasConnection =>
                hasConnection || context.connection ? nextStep() : skipSteps(-2)
            )
        ),
    ];

    private populateConnection(
        ecosystem: Ecosystem,
        partial: Connection
    ): Observable<RxEvent> {
        return this.connectionSvc
            .getEncryptedConnection(ecosystem, partial.connectionId)
            .pipe(
                takeOnce(),
                map(encryptedConnection => {
                    if (encryptedConnection) {
                        const connection = {
                            ...partial,
                            encryptedConnection,
                        };
                        return connectionUpdated({ connection });
                    }
                    return showConnect();
                })
            );
    }
}
