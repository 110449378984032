import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    Input,
    Output,
} from "@angular/core";

import { Association } from "@cloudextend/prp/core";

@Component({
    selector: "prp-associated-records-list",
    templateUrl: "./associated-records-list.component.html",
    styleUrls: ["./associated-records-list.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AssociatedRecordsListComponent {
    @Input() public associations: Association[]; // TODO: Sort

    @Output() public attachmentButtonClicked = new EventEmitter<Association>();
}
