import { CommonModule } from "@angular/common";
import { HttpClientModule } from "@angular/common/http";
import { NgModule } from "@angular/core";

import { PingService } from "@cloudextend/ecosystems/common";

import { BundlePingService } from "./bundle-ping.service";

@NgModule({
    imports: [CommonModule, HttpClientModule],
    providers: [
        { provide: PingService, useClass: BundlePingService },
    ],
})
export class EcosystemsNetsuitePingModule {}
