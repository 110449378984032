import { createSelector } from "@ngrx/store";

import * as fromAssociations from "./associations/associations.reducer";
import * as fromCandidates from "./candidates/candidates.reducer";
import * as Events from "./exports.events";

// Events
export { Events as events };

// composites

export const getAvailableRecords = createSelector(
    fromAssociations.getAssociationsMap,
    fromCandidates.getCandidateRecords,
    (associations, suggestions) =>
        suggestions.filter(s => !associations[`${s.type}:${s.id}`])
);

// Associations
export {
    getAllAssociations,
    getWasAssociationsLoaded,
} from "./associations/associations.reducer";

export {
    associationsLoaded,
    associationsRequired,
} from "./associations/associations.events";

// Mail Item
export {
    getAvailableAttachments,
    getCurrentMailItem,
    getCurrentMailItemId,
    getHasActiveContext,
    getHasAttachments,
} from "./mail-item/mail-item.reducer";

// candidates
export {
    generateCandidateId,
    getCandidate,
    getCandidateRecords,
    getWasSuggestionsLoaded,
    getAssocatiationContext,
} from "./candidates/candidates.reducer";
