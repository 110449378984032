import { OverlayModule } from "@angular/cdk/overlay";
import { PortalModule } from "@angular/cdk/portal";
import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { MatButtonModule } from "@angular/material/button";
import { MatExpansionModule } from "@angular/material/expansion";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatIconModule } from "@angular/material/icon";
import { MatInputModule } from "@angular/material/input";
import { MatListModule } from "@angular/material/list";
import { MatSidenavModule } from "@angular/material/sidenav";
import { MatToolbarModule } from "@angular/material/toolbar";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { RouterModule } from "@angular/router";

import { LetDirectiveModule } from "../let-directive/let-directive.module";

import { AppBannerComponent } from "./app-banner";
import { AppLayoutComponent } from "./app-layout";
import { MainMenuComponent } from "./main-menu";
import { DropdownComponent } from "./main-search-box/internals/dropdown.component";
import { MainSearchBoxComponent } from "./main-search-box/main-search-box.component";

@NgModule({
    declarations: [
        AppBannerComponent,
        AppLayoutComponent,
        DropdownComponent,
        MainMenuComponent,
        MainSearchBoxComponent,
    ],
    imports: [
        BrowserAnimationsModule,
        CommonModule,
        FormsModule,
        MatInputModule,
        LetDirectiveModule,
        MatButtonModule,
        MatExpansionModule,
        MatFormFieldModule,
        MatIconModule,
        MatListModule,
        MatSidenavModule,
        MatToolbarModule,
        OverlayModule,
        PortalModule,
        RouterModule,
    ],
    exports: [
        AppBannerComponent,
        AppLayoutComponent,
        MainMenuComponent,
        MainSearchBoxComponent,
    ],
})
export class CommonLayoutModule {}
