export enum NoticeSeverity { 
    info = 0,
    success = 1,
    warn = 2,
    error = 3,
}

export interface Notice {
    message: string;
    severity?: NoticeSeverity;
}
