import { ChangeDetectionStrategy, Component, Inject } from "@angular/core";
import { MAT_DIALOG_DATA } from "@angular/material/dialog";

import { FileAttachment } from "@cloudextend/prp/core";

export interface PickerParameters {
    targetRecordName: string;
    availableAttachments: FileAttachment[];
    selectedAttachments?: FileAttachment[];
    includeInlineImages?: boolean;
}

@Component({
    selector: "prp-attachments-picker",
    templateUrl: "./attachments-picker.component.html",
    styleUrls: ["./attachments-picker.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AttachmentsPickerComponent {
    constructor(@Inject(MAT_DIALOG_DATA) public data: PickerParameters) {}
}
