import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { ListShimmerComponent } from "./list-shimmer.component";

@NgModule({
    declarations: [ListShimmerComponent],
    imports: [CommonModule],
    exports: [ListShimmerComponent],
})
export class ListShimmerModule {}
