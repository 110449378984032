import {
    AfterContentInit,
    Component,
    ContentChild,
    OnDestroy,
    Output,
    ViewChild,
} from "@angular/core";
import { EventEmitter } from "@angular/core";
import { MatSidenav } from "@angular/material/sidenav";
import { Subscription } from "rxjs";

import { Flyout, FlyoutContent } from "@cloudextend/common/core";

import { ContentHeaderComponent } from "../content-header/content-header.component";

@Component({
    selector: "cloudextend-flyout",
    templateUrl: "./flyout.component.html",
    styleUrls: ["./flyout.component.scss"],
})
export class FlyoutComponent implements AfterContentInit, OnDestroy, Flyout {
    @Output() title: string;
    @Output() taskCompleted = new EventEmitter();

    @ViewChild("sideNav") sideNav: MatSidenav;

    @ViewChild("header") header: ContentHeaderComponent;
    @ContentChild("content") content: FlyoutContent;

    private _contentSubscriptions: Subscription[];

    ngAfterContentInit(): void {
        if (this.content) this.onContentChanged(this.content);
    }

    ngOnDestroy(): void {
        this.destroyConent();
    }

    public onContentChanged(content?: FlyoutContent) {
        this.destroyConent();

        const source = content || this.content;

        if (this.header && source) {
            this.header.title = source.title;
            this.header.canClose = !source.preventClosing;
            this.sideNav.disableClose = source.preventClosing;

            if (source.taskCompleted) {
                this._contentSubscriptions = [
                    source.taskCompleted.subscribe(() =>
                        this.taskCompleted.emit()
                    ),
                ];
            }
        }
    }

    private destroyConent() {
        if (this._contentSubscriptions) {
            this._contentSubscriptions.forEach(s => s.unsubscribe());
            delete this._contentSubscriptions;
        }
    }

    public open(content?: FlyoutContent) {
        this.onContentChanged(content);
        this.sideNav.open();
    }

    public close() {
        this.sideNav.close();
        this.taskCompleted.emit();
    }
}
