import { Component } from "@angular/core";

@Component({
    selector: "cefi-no-subscription-page",
    templateUrl: "./no-subscription-page.component.html",
    styleUrls: ["./no-subscription-page.component.css"],
})
export class NoSubscriptionPageComponent {
   // logout()
}
