import { Injectable } from "@angular/core";
import { asapScheduler, Observable, Subject } from "rxjs";
import { observeOn } from "rxjs/operators";

import { Initializer } from "../bootstrapping/initializer";
import { Logger, LogService } from "../log.service";

import { WindowMessage } from "./window-message";

@Injectable({
    providedIn: "root",
})
export class MessageRelayService implements Initializer {
    constructor(logService: LogService) {
        this.logger = logService.createLogger("MessageRelayService");
    }

    public get messages$(): Observable<WindowMessage> {
        return this.messagingSubject$;
    }

    private readonly logger: Logger;

    private readonly messagingSubject$ = new Subject<WindowMessage>();

    public initialize() {
        // TODO: Filter out all messages from unknown/unconfigured origins.

        window.addEventListener("message", event => {
            const messageData = event.data;

            //TODO: Check for component in event data
            if (!messageData.messageType) {
                return;
            }

            // Make handler async and let the browser continue
            // prettier-ignore
            new Observable(subscriber => {
                const message = {
                    messageOrigin: event.origin,
                    ...messageData,
                } as WindowMessage;

                this.messagingSubject$.next(message);
                subscriber.complete();
            }).pipe(observeOn(asapScheduler)).subscribe();
        });

        this.logger.debug("Message Relay was initialized.");
    }

    /** For Unit Testing purposes only. Do not invoke as it will close the the messaging pipe. */
    public __destroy() {
        this.messagingSubject$.complete();
    }
}
