export interface SubscriptionInfo {
    account: string;
    expireAtEpochSeconds: number;
    status: SubscriptionStatus;
}

export enum SubscriptionStatus {
    Unlicensed = 0, 
    Expired = 1,
    Inactive = 2, 
    Active = 3
}