import { args, declareEvent } from "@cloudextend/common/events";

import { BusyState } from "./busy-state";

export const busy = declareEvent(
    "#state:busy",
    args<Pick<BusyState, "cancellationToken" | "message" | "subText">>()
);
export const idle = declareEvent("#state:idle");

export const busyStateCancelled = declareEvent(
    "#state:cancel-busy",
    args<Pick<BusyState, "cancellationToken">>()
);
