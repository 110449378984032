import { LocationStrategy } from "@angular/common";
import { Injectable } from "@angular/core";
import { NavigationEnd, Router } from "@angular/router";
import { Observable } from "rxjs";
import { filter, takeUntil } from "rxjs/operators";

@Injectable({
    providedIn: "root",
})
export class HistoryService {
    constructor(
        private readonly locationStrategy: LocationStrategy,
        private readonly router: Router
    ) {}

    public disableBackNavigation(enabler: Observable<unknown>) {
        this.router.events
            .pipe(
                takeUntil(enabler),
                filter(event => event instanceof NavigationEnd)
            )
            .subscribe(() => this.preventBackNavigation());
    }

    private preventBackNavigation() {
        history.pushState(null, null, window.location.href);
        this.locationStrategy.onPopState(() => {
            history.pushState(null, null, window.location.href);
        });
    }
}
