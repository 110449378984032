import { Injectable } from "@angular/core";
import { Logger, LogService } from "./log.service";
import { EnvironmentService } from "./environment/environment.service";
import { Environment } from "./environment/environment";
import * as LogRocket from "logrocket";

@Injectable({
    providedIn: "root",
})
export class LogRocketService {
    constructor(
        private _environmentService: EnvironmentService,
        logService: LogService
    ) {
        this.log = logService.createLogger("LogRocketService");
        this.environment = this._environmentService.getEnvironment();
    }
    private readonly environment: Environment;
    private readonly log: Logger;

    public init = () => {
        LogRocket.init(this.environment.logRocketAppId, {
            network: {
                requestSanitizer: request => {
                    if (request.headers["Authorization"]) {
                        request.headers["Authorization"] = "***************";
                    }
                    return request;
                },
            },
        });
        this.log.info("Finished enabling logrocket");
    };

    public identify = (userId: string, metadata: any) => {
        LogRocket.identify(userId, metadata);
        this.log.info("Finished identifying the user");
    };
}
