import { RecordRef } from "@cloudextend/common/core";

import { MailItem } from "./mail-item";

export interface AssociationHistoryItem {
    account: string;
    mailItem: MailItem;
    userEmail: string;
    userId: string;
    createdAtUtc: string;
    isPublic: boolean;
    isAutopiloted: boolean;
    record: RecordRef;
    status: ItemStatus
}

export enum ItemStatus {
    failed = "failed",
    success = "success",
}
