import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { MatBadgeModule } from "@angular/material/badge";
import { MatButtonModule } from "@angular/material/button";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { MatDialogModule } from "@angular/material/dialog";
import { MatDividerModule } from "@angular/material/divider";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatGridListModule } from "@angular/material/grid-list";
import { MatIconModule } from "@angular/material/icon";
import { MatInputModule } from "@angular/material/input";
import { MatListModule } from "@angular/material/list";
import { MatSlideToggleModule } from "@angular/material/slide-toggle";
import { MatTooltipModule } from "@angular/material/tooltip";
import { NgxFilesizeModule } from "ngx-filesize";
import { LottieModule } from "ngx-lottie";

import { CefiStateModule } from "@cloudextend/cefi/state";
import { NotificationsModule } from "@cloudextend/common/core";
import {
    CommonLayoutModule,
    LetDirectiveModule,
    ListShimmerModule,
    UiModule,
} from "@cloudextend/common/ui";

import { NoContextPageComponent } from "./common";
import { NoBundlePageComponent } from "./common/no-bundle-page/no-bundle-page.component";
import {
    AssociatedRecordsListComponent,
    AttachmentsPickerComponent,
    AutopilotBandComponent,
    AvailableRecordsListComponent,
    MessageContextPageComponent,
    WelcomePageComponent,
} from "./contextual";
import { PreferencesModule } from "./preferences/preferences.module";

export function playerFactory() {
    return import("lottie-web");
}

@NgModule({
    imports: [
        // Core Angular
        CommonModule,
        FormsModule,
        // Material
        MatBadgeModule,
        MatButtonModule,
        MatCheckboxModule,
        MatDialogModule,
        MatDividerModule,
        MatFormFieldModule,
        MatGridListModule,
        MatIconModule,
        MatInputModule,
        MatListModule,
        MatSlideToggleModule,
        MatTooltipModule,
        // Other third party
        NgxFilesizeModule,
        LottieModule.forRoot({ player: playerFactory }),
        // Common/UI
        LetDirectiveModule,
        ListShimmerModule,
        CommonLayoutModule,
        NotificationsModule,
        PreferencesModule,
        UiModule,
        // CEFI
        CefiStateModule,
    ],
    declarations: [
        NoContextPageComponent,
        WelcomePageComponent,
        AutopilotBandComponent,
        AttachmentsPickerComponent,
        MessageContextPageComponent,
        AvailableRecordsListComponent,
        AssociatedRecordsListComponent,
        NoBundlePageComponent,
    ],
})
export class PrpUiModule {}
